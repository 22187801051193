import { elementsS1 } from "./partA/section1";
import { elementsS2 } from "./partA/section2";
import { elementsS3 } from "./partB/section3";
import { elementsS4 } from "./partC/section4";
import { elementsS5 } from "./partD/section5";

export const questionnaire4 = {
  id: "4v2",
  preview: "/q4v2preview.png",
  answersNum: 1,
  checkErrorsMode: "onValueChanged",
  tag: "case",
  active: {
    Greece: {
      from: "01/11/2023",
      to: "01/11/2024",
    },
  },
  title: {
    default: "Baseline questionnaire for cases",
    gr: "Βασικό ερωτηματολόγιο ασθενών",
    fr: "Questionnaire initial pour les cas",
    de: "",
    ka: "ბაზისური კითხვარი ალერგიის მქონე პაციენტებისთვის",
    fi: "Lähtötason tapausten kontrolli",
  },
  showQuestionNumbers: "on",
  description: {
    default: "Baseline case control",
    gr: "Βασικό ερωτηματολόγιο ασθενώνv",
    fr: "Questionnaire initial pour les cas",
    de: "",
    ka: "ბაზისური კითხვარი ალერგიის მქონე პაციენტებისთვის",
    fi: "Lähtötason tapausten kontrolli",
  },
  pages: [
    {
      name: "section1",
      // title: {
      //   default: "Asthma",
      //   gr: "Άσθμα",
      //   fr: "Asthme",
      //   de: "",
      //   ka: "ასთმა",
      //   fi: "Astma",
      // },
      elements: elementsS1,
    },
    {
      name: "section2",
      title: {
        default: "Asthma Control Questionnaire",
        gr: "Ερωτηματολόγιο για τον έλεγχο του άσθαματος",
        fr: "Questionnaire de contrôle de l’asthme (ACQ-6)",
        de: "",
        ka: "ასთმის კონტროლის კითხვარი.",
        fi: "Astman kontrollikysely",
      },
      description: {
        default:
          "Select the number of the response that best describes how you have been during the past week.",
        gr: "Σκεφθείτε πως ήσαστε την ΠΕΡΑΣΜΕΝΗ ΕΒΔΟΜΑΔΑ και επιλέξτε τον αριθμό της απάντησης σας που περιγράφει καλύτερα την κατάσταση σας.",
        fr: "Répond aux 6 questions suivantes, en cochant la réponse qui décrit le mieux comment tu as été au cours de la semaine dernière.",
        de: "",
        ka: "გთხოვთ უპასუხოთ კითხვებს 1-6. აირჩიეთ ის რიცხვი, რომელიც ყველაზე მეტად შეესაბამება  ბოლო კვირის განმავლობაში თქვენს მდგომარეობას",
        fi: "Valitse kuvan numero, joka kuvaa parhaiten sitä, miten olet voinut kuluneen viikon aikana.",
      },
      elements: elementsS3,
    },
    {
      name: "section3",
      title: {
        default:
          "Childhood Asthma Control Test for children 4 to 11 years old.",
        gr: "Τεστ για τον έλεγχο του παιδικού άσθματος για παιδιά ηλικίας 4 ως 11 ετών",
        fr: "Test de contrôle de l’asthme chez l’enfant (cACT) – 4-11 ans",
        de: "",
        ka: "ბავშვთა ასთმის კონტროლის ტესტი 4-დან 11 წლამდე ბავშვებისთვის.",
        fi: "Lasten astmatesti 4-11-vuotiaille lapsille.",
      },
      description: {
        default:
          "Let your child respond to the first four questions (1 to 4). If your child \
        needs help reading or understanding the question, you may help, but let your \
        child select the response. Complete the remaining three questions (5 to 7) on your \
        own wihout letting your child's reponse influence your answers. There are \
        no right or wrong answers.",
        gr: "Αφήστε το παιδί σας να απαντήσει στις πρώτες τέσσερις ερωτήσεις (1 έως 4). Αν το \
        παιδί σας χρειαστεί βοήθεια για να διαβάσει ή να καταλάβει κάποια ερώτηση μπορείτε να το \
        βοηθήσετε, αρκεί να το αφήσετε να διαλέξει μόνο του την απάντηση. Απαντήστε μόνος/η σας \
        στις υπόλοιπες τρεις ερωτήσεις (5 έως 7), χωρίς να αφήσετε να σας επηρεάσουν οι απαντήσεις \
        του παιδιού σας. Δεν υπάρχουν σωστές ή λάθος απαντήσεις.",
        fr: "Demandez à votre enfant de répondre à ces questions ",
        de: "",
        ka: "თქვენმა შვილმა პასუხი უნდა გასცეს პირველ 4 შეკითხვას (1 - 4). იმ შემთხვევაში, თუ თქვენი\
        შვილი საჭიროებს დახმარებას ტექსტის წაკითხვაში ან შეკითხვის გააზრებაში, თქვენ მას უნდა \
        დაეხმაროთ, მაგრამ პასუხები ბავშვმა დამოუკიდებლად უნდა აირჩიოს. თქვენ თვითონ შეავსეთ დარჩენილი\
        3 კითხვა (მე-5-დან მე-7-მდე) ისე, რომ ბავშვის აზრმა არ იმოქმედოს თქვენს პასუხებზე. გაითვალისწინეთ,\
        რომ სწორი ან მცდარი პასუხები არ არსებობს.",
        fi: "Anna lapsesi itse vastata neljään ensimmäiseen kysymyk-seen (1–4). Jos lapsesi tarvitsee apua\
        kysymysten lukemi-sessa tai ymmärtämisessä, voit auttaa, mutta anna lapsesi valita vastaus. \
        Vastaa kolmeen jäljellä olevaan kysymyk-seen (5–7) yksin, äläkä anna lapsesi vastausten vaikuttaa \
        omiin vastauksiisi. Oikeita tai vääriä vastauksia ei ole.",
      },
      elements: elementsS4,
    },
    {
      name: "section5",
      // title: {
      //   default: "Food allergy",
      //   gr: "Τροφική αλλεργία",
      //   fr: "Allergie alimentaire",
      //   de: "",
      //   ka: "კვებითი ალერგია",
      //   fi: "Ruoka-allergia",
      // },
      elements: [...elementsS2, ...elementsS5],
    },
    // {
    //   name: "section6",
    //   description: {
    //     default: "SCORAD Calculation",
    //     gr: "",
    //     fr: "",
    //     de: "",
    //     ka: "",
    //     fi: "",
    //   },
    //   elements: elementsS6,
    // },
  ],
};
