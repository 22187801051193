import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { dontknow, no, nosymptoms, unknown, yes } from "../../consts/yes_no";

const s1q1 = {
  type: "radiogroup",
  name: "q4s1q1",
  title: {
    default:
      "Is there any mold or humidity in the house where your child lives?",
    gr: "Υπάρχει καθόλου μούχλα ή υγρασία στο σπίτι που μένει το παιδί σας; ",
    fr: "Y a-t-il de la moisissure ou de l'humidité dans la maison où vit votre enfant ?",
    de: "",
    ka: " სახლში, სადაც თქვენი შვილი ცხოვრობს, აღინიშნება თუ არა ობის ან ნესტის არსებობა?",
    fi: "Onko talossa, jossa lapsesi asuu, hometta tai kosteutta?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q1a = {
  type: "radiogroup",
  name: "q4s1q1a",
  visibleIf: "{q4s1q1} == 'yes' ",
  title: {
    default: "If yes, does this affect child’s symptoms?",
    gr: "Εάν ναι, επηρεάζει τα συμπτώματα του παιδιού σας;",
    fr: "Si oui, cela affecte-t-il les symptômes de votre enfant ?",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ახდენს თუ არა ეს გავლენას თქვენი შვილის სიმპტომებზე?",
    fi: "Jos kyllä, vaikuttaako tämä lapsen oireisiin?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q1b = {
  type: "tagbox",
  name: "q4s1q1b",
  isRequired: isRequired,
  title: {
    default: "If yes, please indicate which symptoms are worse",
    gr: "Εάν ναι παρακαλώ προσδιορίστε ποια συμπτώματα είναι χειρότερα ",
    fr: "Si oui, veuillez indiquer quels symptômes sont les plus graves",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ქვემოთ მოცემულთგან აღნიშნეთ თუ რომელი სიმპტომი (სიმპტომები)უარესდება? ",
    fi: "Jos vastasitte kyllä, ilmoittakaa, mitkä oireet ovat pahempia",
  },
  description: selectMultiple,
  visibleIf: "{q4s1q1a} == 'yes' ",
  choices: [
    {
      value: "cough",
      text: {
        default: "Cough",
        gr: "Βήχας",
        fr: "Toux",
        de: "",
        ka: "ხველა",
        fi: "Yskä",
      },
    },
    {
      value: "wheezing",
      text: {
        default: "Wheezing",
        gr: "Βράσιμο ή σφύριγμα στο στήθος",
        fr: "Sifflements",
        de: "",
        ka: "ხიხინი",
        fi: "Vinkuminen",
      },
    },
    {
      value: "difficulty_in_breathing",
      text: {
        default: "Difficulty in breathing",
        gr: "Δυσκολία στην αναπνοή",
        fr: "Difficulté à respire",
        de: "",
        ka: "სუნთქვის გაძნელება",
        fi: "Hengitysvaikeudet",
      },
    },
    {
      value: "runny_nose",
      text: {
        default: "Runny nose",
        gr: "Συνάχι",
        fr: "Nez qui coule",
        de: "",
        ka: "სურდო",
        fi: "Vuotava nenä",
      },
    },
    {
      value: "stuffy_nose",
      text: {
        default: "Stuffy nose",
        gr: "Μπουκωμένη μύτη",
        fr: "Nez bouché",
        de: "",
        ka: "ცხვირის გაჭედვა",
        fi: "Tukkoinen nenä",
      },
    },
    {
      value: "atopic_eczema",
      text: {
        default: "Atopic eczema",
        gr: "Ατοπικό έκζεμα",
        fr: "Dermatite atopique",
        de: "",
        ka: "ატოპიური ეგზემა",
        fi: "Atooppinen ihottuma",
      },
    },
  ],
};

const s1q2 = {
  type: "radiogroup",
  name: "q4s1q2",
  title: {
    default: "Does your child have recurrent wheeze/asthma? ",
    gr: "Έχει το παιδί σας επαναλαμβανόμενο συριγμό/άσθμα;",
    fr: "Votre enfant souffre-t-il de respiration sifflante/d’asthme récurrent ?",
    de: "",
    ka: "აქვს თუ არა თქვენს შვილს განმეორებითი ხიხინი/ასთმა?",
    fi: "Onko lapsellasi toistuvaa hengityksen vinkumista/astmaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q3new = {
  type: "radiogroup",
  name: "q4s1q3new",
  visibleIf: "{q4s1q2} == 'no' ",
  title: {
    default: "Has your child used any inhalers in the last 12 months?",
    gr: "Έχει χρησιμοποιήσει το παιδί σας συσκευές εισπνοής τους τελευταίους 12 μήνες;",
    fr: "Votre enfant a-t-il utilisé des inhalateurs au cours des 12 derniers mois ?",
    de: "",
    ka: "გამოიყენა თუ არა თქვენს შვილს რაიმე ინჰალატორი ბოლო 12 თვის განმავლობაში?",
    fi: "Onko lapsesi käyttänyt inhalaattoria viimeisten 12 kuukauden aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q3 = {
  type: "radiogroup",
  name: "q4s1q3",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  colCount: 3,
  title: {
    default:
      "How often did your child have asthma symptoms/recurrent wheeze during the day, in the last 3 months",
    gr: "Πόσο συχνά είχε το παιδί σας επεισόδια με σφύριγμα/βράσιμο ή συμπτώματα από το άσθμα τους τελευταίους 3 μήνες: ",
    fr: "À quelle fréquence votre enfant a-t-il/elle eu des symptômes d'asthme/de respiration sifflante pendant la journée, au cours des 3 derniers mois",
    de: "",
    ka: "ბოლო 3 თვის განმავლობაში, რამდენად ხშირად უვლინდებოდა თქვენს შვილს  განმეორებითი ხიხინი/ასთმის სიმპტომები?",
    fi: "Kuinka usein lapsellasi oli toistuvaa hengityksen vinkumista/astmaoireita päivän aikana viimeisten 3 kuukauden aikana",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "< 1/week",
        gr: "< 1/εβδομάδα",
        fr: "<1 fois par semaine",
        de: "",
        ka: "< 1/კვირაში",
        fi: "< 1/vko",
      },
      value: "less_than_1_pw",
    },
    {
      text: {
        default: ">1week but <1/day",
        gr: "1/εβδομάδα αλλά <1/ημέρα",
        fr: "> 1 fois par semaine, mais < 1 fois par jour ",
        de: "",
        ka: ">1კვირაში, მაგრამ <1/დღის მანძილზე",
        fi: "> 1/vko mutta < 1/pv",
      },
      value: "more_than_1_pw",
    },
    {
      text: {
        default: "Daily",
        gr: "Καθημερινά",
        fr: "Tous les jours",
        de: "",
        ka: "ყოველდღიურად",
        fi: "päivittäin",
      },
      value: "daily",
    },
    // nosymptoms,
  ],
};

const s1q4 = {
  type: "radiogroup",
  name: "q4s1q4",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  colCount: 3,
  title: {
    default:
      "How often did your child have asthma symptoms/recurrent wheeze during the night,in the last 3 months",
    gr: "Πόσο συχνά είχε το παιδί σας επεισόδια με σφύριγμα/βράσιμο ή συμπτώματα από το άσθμα κατά τη διάρκεια της νύχτας τους τελευταίους 3 μήνες:",
    fr: "À quelle fréquence votre enfant a-t-il/elle eu des symptômes d'asthme pendant la nuit, au cours des 3 derniers mois",
    de: "",
    ka: " ბოლო 3 თვის განმავლობაში, რამდენად ხშირად უვლინდებოდა თქვენს შვილს ასთმის სიმპტომები ღამით",
    fi: "Kuinka usein lapsellasi oli astmaoireita yön aikana viimeisten 3 kuukauden aikana",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 times/month",
        gr: "≤2  φορές/μήνα",
        fr: "≤ 2 fois/mois",
        de: "",
        ka: "≤2 -ჯერ/თვეში",
        fi: "≤ 2 krt/kk",
      },
      value: "less_than_2_pm",
    },
    {
      text: {
        default: ">2 times/month",
        gr: ">2 φορές/μήνα",
        fr: "> 2 fois /mois",
        de: "",
        ka: ">2 -ჯერ/თვეში",
        fi: ">2 krt/kk",
      },
      value: "more_than_2_pm",
    },
    {
      text: {
        default: ">1week",
        gr: ">1/εβδομάδα",
        fr: "> 1 fois/semaine",
        de: "",
        ka: ">1/კვირაში",
        fi: ">1/vko",
      },
      value: "more_than_1_pw",
    },
    {
      text: {
        default: "Almost daily",
        gr: "Σχεδόν καθημερινά",
        fr: "Presque tous les jours",
        de: "",
        ka: "თითქმის ყოველ ღამით",
        fi: "lähes päivittäin",
      },
      value: "daily",
    },
    // nosymptoms,
  ],
};

const s1d1 = {
  type: "html",
  name: "subheading",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  html: {
    default:
      "<p>What treatment did your child use for his/her asthma symptoms/recurrent wheeze the last 3 months:</p>",
    gr: "<p>Τι θεραπεία χρησιμοποιήσε το παιδί σας για τα επεισόδια με σφύριγμα/βράσιμο ή για το άσθμα του τους τελευταίους 3 μήνες:</p>",
    fr: "<p>Quel(s) traitement(s) votre enfant a-t-il/elle utilisé pour l’asthme, au cours des 3 derniers mois ?</p>",
    de: "<p></p>",
    ka: "<p>ბოლო 3 თვის განმავლობაში, რა სახის მკურნალობას იღებდა თქვენი შვილი ასთმის სამკურნალოდ?</p>",
    fi: "<p> Mitä hoitoa lapsesi on käyttänyt astmaansa viimeisten 3 kuukauden aikana</p>",
  },
};

const s1q5a = {
  type: "radiogroup",
  name: "q4s1q5a",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default:
      "Relievers /rescue treatment (Salbutamol/Salamol/Aerolin/Ventolin):",
    gr: "Θεραπεία ανακουφιστική /διάσωσης (Aerolin-το 'μπλε')",
    fr: "Traitement de secours/crise (Ventoline, Airomir, Ventilastin…)",
    de: "",
    ka: "გადაუდებელი ჩარევისთვის საჭირო, სიმპტომების სწრაფად მოხნისთვის განკუთვნილ საშუალებებს (აეროლინი/ვენტოლონი):",
    fi: "Lievityslääkkeet/varahoito (Aerolin/Ventolin):",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q5ai = {
  type: "radiogroup",
  name: "q4s1q5ai",
  visibleIf:
    " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'and {q4s1q5a} == 'yes' ",
  colCount: 2,
  title: {
    default: "If yes how often:",
    gr: "Εάν ναι πόσο συχνά:",
    fr: "Si oui, avec quelle fréquence",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, რა სიხშირით?",
    fi: "Jos kyllä, kuinka usein:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 days/week",
        gr: "≤2 ημέρες/εβδομάδα",
        fr: "≤2 jours/semaine",
        de: "",
        ka: "≤2 დღე/კვირაში",
        fi: "≤2 päivää/viikko",
      },
      value: "less_than_2d_pw",
    },
    {
      text: {
        default: ">2days/week ",
        gr: ">2ημέρες /εβδομάδα",
        fr: ">2 jours/semaine",
        de: "",
        ka: ">2დღე/ კვირაში",
        fi: ">2 päivää/viikko",
      },
      value: "more_than_2d_pw",
    },
  ],
};

const s1q5b = {
  type: "radiogroup",
  name: "q4s1q5b",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default: "Daily controller/preventive medication:",
    gr: "Καθημερινή θεραπεία συντήρησης-ελέγχου:",
    fr: "Traitement de fond",
    de: "",
    ka: "სიმპტომების კონტროლისთვის განკუთვნილ ყოველდღიურ საშუალებებს?",
    fi: "Päivittäinen kontrollilääkitys",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q6 = {
  name: "q4s1q6",
  type: "radiogroup",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default: " Is your child receiving Allergen Immunotherapy?",
    gr: "Λαμβάνει το παιδί σας ανοσοθεραπεία; ",
    fr: "Votre enfant est-il en cours de traitement par une immunothérapie allergénique (désensibilisation) ?",
    de: "",
    ka: "ხომ არ იღებს თქვენი შვილი იმუნოთერაპიას?",
    fi: "Onko lapsesi saanut allergiaan siedätyshoitoa ?",
  },
  choices: [yes, no],
  isRequired: isRequired,
};

// {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'
const s1q6a = {
  name: "q4s1q6a",
  type: "text",
  visibleIf: "{q4s1q6} == 'yes'",
  title: {
    default: "If yes, how long?",
    gr: " Εάν ναι, πόσο καιρό; ",
    fr: "Si oui, depuis combien de temps ? ",
    de: "",
    ka: "თუ კი, რამდენი ხანი?",
    fi: "Jos kyllä, kuinka kauan?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 5,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 5",
      minValue: 1,
      maxValue: 5,
    },
  ],
};

const s1q7 = {
  type: "radiogroup",
  name: "q4s1q7",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default:
      "Are your child’s activities limited by asthma symptoms/recurrent wheeze?",
    gr: "Περιορίζονται οι δραστηριότητες του παιδιού σας από τα επεισόδια με σφύριγμα/βράσιμο ή τα συμπτώματα του άσθματος; ",
    fr: "Les activités de votre enfant sont-elles limitées par des symptômes d'asthme ?",
    de: "",
    ka: "ზღუდება თუ არა თქვენი შვილის ფიზიკური აქტიურობა ასთმის გამო?",
    fi: "Rajoittavatko astman oireet lapsesi toimintaa?",
  },
  isRequired: isRequired,

  choices: [yes, no, dontknow],
};

const s1q8 = {
  type: "radiogroup",
  name: "q4s1q8",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default: "Is your child completely well between symptomatic periods?",
    gr: "Μεταξύ των επεισοδίων είναι το παιδί σας τελείως καλά;",
    fr: "Votre enfant va-t-il bien entre les périodes symptomatiques ",
    de: "",
    ka: "გრძნობს თუ არა თქვენი შვილი თავს სრულებით კარგად სიმპტომების გამწვავებებს შორის პერიოდებში?",
    fi: "Voiko lapsesi täysin hyvin oireellisten periodien välillä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q9 = {
  name: "q4s1q9",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  type: "text",
  title: {
    default:
      "How many episodes of wheezing/asthma/cough did your child had in the last 3 months?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 3 μήνες;",
    fr: "Combien d'épisodes de respiration sifflante/d'asthme/de toux votre enfant a-t-il eu au cours des 3 derniers mois ?",
    de: "",
    ka: "ბოლო 3 თვის მანძილზე  რამდენი ეპიზოდი ჰქონდა თქვენს შვილს?",
    fi: "Kuinka monta hengityksen vinkumista/astmaa/yskää lapsellasi oli viimeisen 3 kuukauden aikana?",
  },
  defaultValue: 0,
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 12,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 12",
      minValue: 1,
      maxValue: 12,
    },
  ],
};

const s1q10 = {
  name: "q4s1q10",
  type: "text",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default:
      "How many episodes of wheezing/asthma/cough did your child had in the last 12 months?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 12 μήνες;",
    fr: "Combien d'épisodes de respiration sifflante/d'asthme/de toux votre enfant a-t-il eu au cours des 12 derniers mois ? ",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე  ხიხინის/ასთმის/ხველისრამდენი ეპიზოდი ჰქონდა თქვენს შვილს?",
    fi: "Kuinka monta hengityksen vinkumista/astmaa/yskää lapsellasi oli viimeisen 12 kuukauden aikana?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 52,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 52",
      minValue: 1,
      maxValue: 52,
    },
  ],
};

const s1q11 = {
  name: "q4s1q11",
  type: "text",
  visibleIf: " {q4s1q2} == 'yes' or {q4s1q3new} == 'yes'",
  title: {
    default:
      "How many hospitalizations for wheezing/asthma/cough did your child had in the last 12 months?",
    gr: "Πόσες νοσηλείες για «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 12 μήνες;",
    fr: "Combien d'hospitalisations pour respiration sifflante/asthme/toux votre enfant a-t-il eu au cours des 12 derniers mois ?",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე  ხიხინის/ასთმის/ხველის გამო რამდენჯერ დასჭირდა თქვენს შვილს ჰოსპიტალიზაცია? ",
    fi: "Kuinka monta sairaalahoitoa hengityksen vinkumisen/astman/yskän vuoksi lapsella on ollut viimeisen 12 kuukauden aikana?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 24,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 24",
      minValue: 1,
      maxValue: 24,
    },
  ],
};

export const elementsS1 = [
  s1q1,
  s1q1a,
  s1q1b,
  s1q2,
  s1q3new,
  s1q3,
  s1q4,
  s1d1,
  s1q5a,
  s1q5ai,
  s1q5b,
  s1q6,
  s1q6a,
  s1q7,
  s1q8,
  s1q9,
  s1q10,
  s1q11,
];
