"use client";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import { useOidc } from "@axa-fr/react-oidc";
import MapView from "../../../components/Map";
import gjsonAdapter from "../../../utils/gjsonAdapter";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { selectSchools } from "../../../store/selectors/schoolSelectors";
import { useEffect, useState } from "react";
import { getSchools } from "../../../store/effects/schoolEffects";
import CardComponent from "../../../components/Card";
import {
  selectBarChartWidgetQs,
  selectLineChartWidget,
  selectNoAnswersStudents,
  selectNoAnswersStudents24h,
  selectReportingError,
} from "../../../store/selectors/reportingSelector";
import {
  getBarChartQuestionnaires,
  getLineChartQuestionnaires,
  getNoAnswersStudents24h,
  getNoAnswersStudentsTable,
} from "../../../store/effects/reportingEffects";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { getUsers } from "../../../store/effects/userEffects";
import { getCountries } from "../../../store/effects/countriesEffects";
import { selectCountries } from "../../../store/selectors/countries";
import LineChart from "../../../components/dashboardComps/LineChart";
import BarChart, {
  StackedBarChart,
} from "../../../components/dashboardComps/BarChart";
import TableDash from "../../../components/dashboardComps/TableDash";
import { School } from "../../../types/school";
import { fetchSchoolsByCountry } from "../../../services/api/schools.api";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/useAuth";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import { clearError } from "../../../store/actions/reportingActions";

const DashboardPage = () => {
  const router = useNavigate();
  // const { isAuthenticated } = useOidc();
  const isAuthenticated = useAuth();
  const dispatch = useAppThunkDispatch();
  // const students = useAppSelector(selectStudents);
  const schools = useAppSelector(selectSchools);
  const [filtered_schools, setFilteredSchools] = useState<School[]>(schools);
  const countries = useAppSelector(selectCountries);
  const barChartData = useAppSelector(selectBarChartWidgetQs);
  const lineChartData = useAppSelector(selectLineChartWidget); //Object.entries(lineChartData)
  const tableData = useAppSelector(selectNoAnswersStudents);
  const noAnswersStudents24h = useAppSelector(selectNoAnswersStudents24h);
  const dispatchedError = useAppSelector(selectReportingError);
  const [error, setError] = useState<string | undefined>();

  console.log("DAtA", tableData);

  useEffect(() => {
    // dispatch(getQuestionnaires());
    dispatch(getCountries());
    dispatch(getSchools());
    dispatch(getUsers());
    dispatch(getLineChartQuestionnaires());
    dispatch(getBarChartQuestionnaires());
    dispatch(getNoAnswersStudentsTable());
    dispatch(getNoAnswersStudents24h());
  }, [dispatch]);

  useEffect(() => {
    if (countries.length === 1) {
      fetchSchoolsByCountry(countries[0].name)
        .then((res) => setFilteredSchools(res.data.data))
        .catch((e) => {
          handleApiError(e, setError);
        });
    }
  }, [countries]);

  return (
    <>
      <Container maxWidth={false}>
        <PermissionControl
          permissionPath={permissions.pages.dashboard.access}
          role={getRole()}
        >
          <Grid
            container
            item
            columnSpacing={{
              lg: 0,
              xl: 2,
            }}
            rowSpacing={4}
          >
            <Grid container item sm={12} lg={6}>
              <BarChart
                data={barChartData["Answered"]}
                countries={countries.map((country) => country.name)}
              />
              {/* <StackedBarChart
                    data={barChartData}
                    countries={countries}
                  /> */}
            </Grid>
            <Grid container item sm={12} lg={6}>
              <LineChart
                data={lineChartData}
                countries={countries.map((country) => country.name)}
                schools={filtered_schools}
              />
            </Grid>
            <Grid container item sm={12} lg={6} alignItems={"center"}>
              <Container maxWidth={"xl"}>
                {/* with school.length>0 check it works */}
                <MapView schools={gjsonAdapter(schools)} />
              </Container>
            </Grid>
            <Grid container item sm={6} lg={3}>
              <CardComponent
                title={"Answered (24h)"}
                content={noAnswersStudents24h.answered}
                suffix={"questionnaires"}
              />
            </Grid>
            <Grid container item sm={6} lg={3}>
              <CardComponent
                title={"Not answered (24h)"}
                content={noAnswersStudents24h.notAnswered}
                suffix={"students"}
              />
            </Grid>
            <Grid container item sm={12} lg={12}>
              <TableDash
                data={tableData}
                countries={countries.map((country) => country.name)}
                schools={filtered_schools}
              />
            </Grid>
          </Grid>
        </PermissionControl>
        {(error || dispatchedError) && (
          <AlertDialog
            title={"System Error"}
            message={error || dispatchedError || ""}
            buttons={[
              {
                label: "Ok",
                onClick: () => {
                  setError(undefined);
                  dispatch(clearError());
                },
              },
            ]}
          />
        )}
      </Container>
    </>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // background: "rgb(255 255 255 / 75%)",
    // borderRadius: "0.5em",
    width: "100%",
    // border: "1px solid #eaeaea",
    // height: "200px",
  },
};

export default DashboardPage;
