import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectUsers = (state: RootState) => state.users.users;
export const selectStudents = (state: RootState) => state.users.students;
export const selectCaseStudents = (state: RootState) => state.users.caseStudents;
export const selectUsersLoading = (state: RootState) => state.users.loading;
export const selectActiveUser = (state: RootState) => state.users.activeUser;
export const selectProfile = (state: RootState) => state.users.profile;

// const selectUserById = createSelector(
//   [(state) => state.users, (state, id) => id],
//   (users, id) => users.filter((user: User) => user._id === id)
// );

