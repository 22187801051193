import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Modal,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTable from "../../../components/Table/CustomTable";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Student, User, UserSchema } from "../../../types/user";
import { Column } from "../../../types/table";
import { TableBar } from "../../../components/styled/AppBar";
import {
  AddMultiple,
  AddOne,
  StyledDownloadIcon,
  StyledIconButton,
  StyledUplaodIcon,
} from "../../../components/styled/AddButton";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectCaseStudents,
  selectStudents,
  selectUsersLoading,
} from "../../../store/selectors/userSelector";
import {
  Clear,
  DownloadForOffline,
  FileUpload,
  Search,
} from "@mui/icons-material";
import { getUsers } from "../../../store/effects/userEffects";
import { removeUser } from "../../../store/actions/userActions";
import Loading from "../../../components/Loading";
import {
  deleteUser,
  deleteUserPerm,
  fetchStudentByCode,
  findStudentsByUsername,
} from "../../../services/api/users.api";
import { StyledTextField } from "../../../components/styled/TextField";
import { getRole } from "../../../services/api/utils.api";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getTitle } from "../../../utils/getSchoolName";
import { selectSchools } from "../../../store/selectors/schoolSelectors";
import { usePathname } from "../../../hooks/usePathname";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../consts/theme";
import { StyledFormControl } from "../../../components/styled/StyledFormSelect";
import { StyledSwitch } from "../../../components/styled/StyledSwitch";
import { getSchools } from "../../../store/effects/schoolEffects";
import GenerateStudentsForm from "../../../components/GenerateStudentsForm";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import { debounce } from "lodash";
import InfoIcon from "@mui/icons-material/Info";

const Players = () => {
  const role = getRole();
  const [value, setValue] = useState("");
  const PLAYER_TEXT = "Player";
  const students: Student[] = useAppSelector(selectStudents);
  const caseStudents: Student[] = useAppSelector(selectCaseStudents);
  const [showCase, setShowCase] = useState(false);
  const [showNoSchool, setShowNoSchool] = useState(false);
  const schools = useAppSelector(selectSchools);
  const [data, setData] = useState(students);
  const isLoading = useAppSelector(selectUsersLoading);
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const router = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();
  const [rowDel, setRowDel] = useState<User | undefined>();
  const [rowRem, setRowRem] = useState<User | undefined>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  // console.log(students);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getSchools());
  }, [dispatch]);

  const handleRemoveDelete = () => {
    if (rowRem) {
      const row = rowRem;
      console.log(row);
      deleteUser(row)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUser(row._id!));
            setRowRem(undefined);
          }
        })
        .catch((e) => {
          handleApiError(e, setError);
        });
    } else if (rowDel) {
      const row = rowDel;
      console.log(row);
      deleteUserPerm(row)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUser(row._id!));
            setRowDel(undefined);
          }
        })
        .catch((e) => {
          handleApiError(e, setError);
        });
    }
  };

  const ProcessRows = (data: Student[]): UserSchema<Student>[] => {
    return data.map((row) => {
      const actions = [
        {
          label: "Edit",
          action: (e: React.MouseEvent<HTMLElement>) => {
            router(`${pathname}/${row._id}/`);
          },
          show: role ? permissions.pages.players.update.includes(role) : false,
        },
        {
          label: "Remove",
          action: (e: React.MouseEvent<HTMLElement>) => {
            // console.log(row._id);

            setAlert(
              `User ${row._id} will be removed from dashboard. Their data will remain in storage. Do you wish to proceed?`
            );
            setRowRem(row);
          },
          show: role ? permissions.pages.players.delete.includes(role) : false,
        },
        {
          label: "Delete",
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row);
            setAlert(
              `This user and its data will be deleted permanently. Do you wish to permanently delete ${row._id}?`
            );
            setRowDel(row);
          },
          show: role ? permissions.pages.players.delete.includes(role) : false,
        },
      ];

      !actions.some((action) => action.show === true) &&
        actions.push({
          label: "No Available Actions",
          action: (e: React.MouseEvent<HTMLElement>) => {
            return;
          },
          show: true,
        });

      // const school =
      //   row.schools && row.schools.length > 0
      //     ? getTitle(schools, row.schools[0], row._id) || ""
      //     : row.school
      //     ? getTitle(schools, row.school, row._id) || ""
      //     : "";

      let school = "";

      if (row && row.schools && row.schools.length > 0) {
        school = getTitle(schools, row.schools[0]) || "";
      } else if (row && row.school) {
        school = getTitle(schools, row.school) || "";
      }

      const registered = row.registered ? "Yes" : "No";

      return { ...row, registered, actions, schools: [school] };
    });
  };

  const columns: Column<Student, keyof Student>[] = [
    // { name: "ID", key: "_id" },
    { name: "USERNAME", key: "_id" },
    // { name: "EMAIL", key: "email" },
    // { name: "UNIQUE ID", key: "offline_code" },
    { name: "REGISTERED", key: "registered" },
    // { name: "TEACHER", key: "teacher" },
    { name: "SCHOOL", key: "schools" },
    // { name: "CASE", key: "tags"},
    { name: "QUESTIONNAIRE COUNT", key: "answered_questionnaires" },
    { name: "GARMIN ID", key: "garmin_id" },
    { name: "GARMIN LAST USED", key: "garmin_last_used" },
  ];

  const handleClickToCreate = () => {
    router(`${pathname}/create${PLAYER_TEXT}`);
  };

  const handleClickToUpload = () => {
    router(`${pathname}/upload`);
  };

  const handleSearchClick = () => {
    if (value === "") {
      setData(students);
    } else {
      findStudentsByUsername(value)
        .then((data) => setData(data.data.data))
        .catch((e) => {
          handleApiError(e, setError);
        });
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      // if (value.length >= 3) {
      if (value === "") {
        setData(students);
      } else {
        findStudentsByUsername(value)
          .then((data) => setData(data.data.data))
          .catch((e) => {
            handleApiError(e, setError);
          });
      }
      // }
    }, 500),
    []
  );

  const handleChange = (event: { target: { value: any } }) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedHandleSearch(newValue);
  };

  const handleClear = (e: SyntheticEvent) => {
    setValue("");
    setData(students);
  };

  const handleCaseChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setStudentCase(e.target.value);
    // if (e.target.value === "asthmatic") {
    //   setData(asthmaticStudents);
    // } else {
    //   setData(students);
    // }
    setShowCase(e.target.checked);
    if (e.target.checked) {
      showNoSchool
        ? setData(
            caseStudents.filter(
              (student) => !student.schools || student.schools.length === 0
            )
          )
        : setData(caseStudents);
    } else {
      showNoSchool
        ? setData(
            students.filter(
              (student) => !student.schools || student.schools.length === 0
            )
          )
        : setData(students);
    }
  };

  const handleNoSchoolChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowNoSchool(e.target.checked);
    if (e.target.checked) {
      setData(
        data.filter(
          (student) => !student.schools || student.schools.length === 0
        )
      );
    } else {
      showCase ? setData(caseStudents) : setData(students);
    }
  };

  const PlayersTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "10px", md: "20px" },
              marginRight: "10px",
            }}
          >
            <h2>{`${PLAYER_TEXT}s`}</h2>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "80%",
              }}
            >
              <StyledTextField
                variant='outlined'
                value={value}
                onChange={handleChange}
                placeholder='Type here…'
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "50px",
                  "& fieldset": {
                    borderRadius: "50px",
                  },
                }}
                autoFocus
                size='small'
                onKeyDown={(ev) => {
                  // console.log(`Pressed keyCode ${ev.key}`);
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    handleSearchClick();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      // title='Search by username'
                      aria-label='Search'
                      size='small'
                      // style={{ visibility: value ? "visible" : "hidden" }}
                      onClick={(e) => handleSearchClick()}
                    >
                      <Search fontSize='small' />
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton
                      title='Clear'
                      aria-label='Clear'
                      size='small'
                      style={{ visibility: value ? "visible" : "hidden" }}
                      onClick={(e) => handleClear(e)}
                    >
                      <Clear fontSize='small' />
                    </IconButton>
                  ),
                }}
              />
              <Tooltip
                title='Search by username or by garmin id'
                placement='top-start'
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: { xs: "10px", sm: "20px", md: "30px" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ThemeProvider theme={theme}>
                {/* <InputLabel id="case-filter">Case</InputLabel>
                <Select
                  labelId="case-filter"
                  id="case-filter"
                  value={studentCase}
                  label="Age"
                  onChange={(e) => handleCaseChange(e)}
                  size="small"
                >
                  <MenuItem value={"asthmatic"}>Asthmatic</MenuItem>
                  <MenuItem value={""}>No option</MenuItem>
                </Select> */}
                <FormControlLabel
                  control={
                    <StyledSwitch
                      name='case'
                      size='small'
                      onChange={(e) => handleCaseChange(e)}
                    />
                  }
                  label={
                    <Typography variant='body1' sx={{ fontSize: "15px" }}>
                      Show Cases
                    </Typography>
                  }
                  checked={showCase}
                  sx={{ color: theme.palette.primary.main }}
                />
                <FormControlLabel
                  control={
                    <StyledSwitch
                      name='no-school'
                      size='small'
                      onChange={(e) => handleNoSchoolChange(e)}
                    />
                  }
                  label={
                    <Typography variant='body1' sx={{ fontSize: "15px" }}>
                      Show Without School
                    </Typography>
                  }
                  checked={showNoSchool}
                  sx={{ color: theme.palette.primary.main }}
                />
              </ThemeProvider>
            </Box>
            <PermissionControl
              permissionPath={permissions.pages.players.create}
              role={role}
              route={false}
            >
              <Tooltip title={`Bulk Upload ${PLAYER_TEXT}s`} placement='bottom'>
                <StyledIconButton
                  aria-label='add-multiple'
                  onClick={() => handleClickToUpload()}
                  edge='end'
                  background={"transparent"}
                  shadow='true'
                >
                  <StyledUplaodIcon />
                </StyledIconButton>
              </Tooltip>
            </PermissionControl>
            <PermissionControl
              permissionPath={permissions.pages.players.access}
              role={role}
              route={false}
            >
              <Tooltip
                title={`Bulk Generate ${PLAYER_TEXT}s`}
                placement='bottom'
              >
                <StyledIconButton
                  aria-label='add-multiple'
                  onClick={() => handleOpen()}
                  edge='end'
                >
                  <StyledDownloadIcon />
                </StyledIconButton>
              </Tooltip>
            </PermissionControl>
            <PermissionControl
              permissionPath={permissions.pages.players.create}
              role={role}
              route={false}
            >
              <Tooltip title={`Create New ${PLAYER_TEXT}`} placement='bottom'>
                <StyledIconButton
                  aria-label='add'
                  onClick={() => handleClickToCreate()}
                  edge='end'
                >
                  <AddOne />
                </StyledIconButton>
              </Tooltip>
            </PermissionControl>
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth='xl'>
      <PermissionControl
        permissionPath={permissions.pages.players.access}
        role={role}
      >
        <CustomTable
          toolbar={PlayersTableToolbar}
          rows={ProcessRows(data)}
          columns={columns}
        />
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-school-form'
          aria-describedby='modal-school-form'
        >
          <div>
            <GenerateStudentsForm handleClose={handleClose} />
          </div>
        </Modal>
      </PermissionControl>
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Warning"}
          message={error || alert || ""}
          buttons={
            error
              ? [
                  {
                    label: "Ok",
                    onClick: () => {
                      setError(undefined);
                    },
                  },
                ]
              : [
                  {
                    label: "Ok",
                    onClick: () => {
                      setAlert(undefined);
                      handleRemoveDelete();
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {
                      setAlert(undefined);
                      setRowDel(undefined);
                      setRowRem(undefined);
                    },
                    outlined: true,
                  },
                ]
          }
        />
      )}
    </Container>
  );
};

export default Players;
