import { isEqual } from "lodash";
import Widget from "./Widget";
import { BACKGROUND_COLORS, BORDER_COLORS } from "./consts";
import { memo } from "react";
import { ChartWidget } from "../../types/widget";
import { LineProps } from "../../types/dashboard";
import { getLineChartQuestionnaires } from "../../store/effects/reportingEffects";
import { QueryParams } from "../../types/queries";
import { useAppThunkDispatch } from "../../store/hooks";

const LineChart: React.FC<LineProps> = memo((props) => {
  const dispatch = useAppThunkDispatch();
  // console.log("MEMO: ", props.data);
  // console.log("LineChart Memo: ", props.schools);

  const widgetLineChartCallBack = (params: QueryParams) => {
    // console.log("From LineChart: ", params);
    dispatch(getLineChartQuestionnaires(params));
  };

  const data = {
    datasets: [
      {
        label: "Questionnaires",
        data: props.data,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const lineChartConfig: ChartWidget = {
    toolbarConfig: {
      datepicker: true,
      datepickerConfig: { maxInterval: 30 },
      country: true,
      countryConfig: {
        countries: props.countries,
        required: false,
      },
      schools: props.schools,
      queryCB: widgetLineChartCallBack,
    },
    chartConfig: {
      type: "line",
      title: "Answered Questionnaires",
      data: data,
    },
  };
  return <Widget config={lineChartConfig} />;
}, isEqual); 

// (prevProps, currentProps) => isDeepEqual(prevProps.data, currentProps.data)
// function isDeepEqual(prev: FrequencyObject, cur: FrequencyObject): boolean {
//   const _ = require("lodash");

//   return _.isEqual(prev, cur);
// }

export default LineChart;


// export const LineChartNoMemo: React.FC<LineProps> = (props) => {
//   console.log("NO MEMO: ", props.data);
//   const dataLine = {
//     datasets: [
//       {
//         label: "Questionnaires",
//         data: props.data,
//         backgroundColor: BACKGROUND_COLORS,
//         borderColor: BORDER_COLORS,
//         borderWidth: 1,
//       },
//     ],
//   };

//   const lineChartConfig: ChartWidget = {
//     toolbarConfig: {
//       datepicker: true,
//       datepickerConfig: { maxInterval: 30 },
//       country: true,
//       countryConfig: {
//         countries: [], //props.countries,
//         required: false,
//       },
//       schools: [], //schools,
//       queryCB: () => {}, //widgetLineChartCallBack,
//     },
//     chartConfig: {
//       type: "line",
//       title: "Answered Questionnaires",
//       data: dataLine,
//     },
//   };
//   return <Widget config={lineChartConfig} />;
// };
