import React, { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SchoolIcon from "@mui/icons-material/School";
import Tooltip from "@mui/material/Tooltip";
import { AppBar } from "./styled/AppBar";
import { Drawer, DrawerHeader, StyledDivider } from "./styled/Drawer";
import { LOGO } from "../consts/title";
import { StyledListButton, StyledListText } from "./styled/List";
import { useAppSelector, useAppThunkDispatch } from "../store/hooks";
import { selectProfile } from "../store/selectors/userSelector";
import { useOidc } from "@axa-fr/react-oidc";
import { AddBox, Dashboard } from "@mui/icons-material";
import { getRole } from "../services/api/utils.api";
import { PageType } from "../types/permissions";
import { Link, NavLink } from "react-router-dom";
import { usePathname } from "../hooks/usePathname";
import { PermissionControl } from "./Permission";
import { permissions } from "../utils/permissions";
import { Typography } from "@mui/material";
import { useLogout } from "../hooks/auth/useLogout";
import { resetAction } from "../store/actions/userActions";
import { persistor } from "../store/store";

const drawerWidth = 240;

const Nav = () => {
  // const { logout } = useOidc();
  const { logout } = useLogout();
  const dispatch = useAppThunkDispatch();

  const [open, setOpen] = useState(false);
  const paths = [
    "Dashboard",
    "Users",
    "Players",
    "Schools",
    "Analytics",
    "Questionnaires",
    // "Generator",
    "Logout",
  ];
  const pathName = usePathname().split("/");
  const temp = pathName.length > 2 ? pathName[2] : "";
  const subTitle = temp.charAt(0).toUpperCase() + temp.slice(1);
  const profile = useAppSelector(selectProfile);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const getIcon = (text: string) => {
    switch (text) {
      case "Dashboard":
        return <Dashboard />;
      case "Users":
        return <PersonIcon />;
      case "Players":
        return <AccountBoxIcon />;
      case "Analytics":
        return <AssessmentIcon />;
      case "Schools":
        return <SchoolIcon />;
      case "Questionnaires":
        return <AssignmentIcon />;
      // case "Generator":
      //   return <AddBox/>;
      case "Logout":
        return <ExitToAppRoundedIcon />;
      default:
        return <></>;
    }
  };

  const getListItem = (text: string) => {
    const sxItemButton = {
      minHeight: 48,
      justifyContent: open ? "initial" : "center",
      px: 2.5,
    };

    const sxItemIcon = {
      color: "inherit",
      minWidth: 0,
      mr: open ? 3 : "auto",
      justifyContent: "center",
    };

    return (
      <PermissionControl
        key={text}
        permissionPath={
          text === "Logout"
            ? permissions.pages["login" as PageType].access
            : permissions.pages[text.toLowerCase() as PageType].access
        }
        role={getRole()}
        route={false}
      >
        <Tooltip
          key={text}
          title={text}
          disableHoverListener={open}
          placement="right"
        >
          <ListItem key={text} disablePadding sx={{ display: "block" }}>
            <StyledListButton
              sx={sxItemButton}
              component={NavLink}
              to={
                text === "Logout"
                  ? "/login"
                  : text === "Dashboard"
                  ? "/dashboard"
                  : `/dashboard/${text.toLowerCase()}`
              }
              onClick={() => {
                if (text === "Logout") {
                  dispatch(resetAction());
                  persistor.purge();
                  logout();
                }
              }}
            >
              <ListItemIcon sx={sxItemIcon}>{getIcon(text)}</ListItemIcon>
              <StyledListText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </StyledListButton>
          </ListItem>
        </Tooltip>
      </PermissionControl>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} drawerwidth={drawerWidth}>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
                // ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <PermissionControl
              permissionPath={permissions.pages.dashboard.access}
              role={getRole()}
              route={false}
            >
              <Link to={"/dashboard"}>{LOGO}</Link>
            </PermissionControl>
            <PermissionControl
              permissionPath={["student"]}
              role={getRole()}
              route={false}
            >
              {LOGO}
            </PermissionControl>
          </Box>
          <h2>{subTitle}</h2>
        </Toolbar>
      </AppBar>
      <Drawer
        transitionDuration={700}
        variant={open ? "temporary" : "permanent"}
        anchor="left"
        open={open}
        drawerwidth={drawerWidth}
        onClose={() => setOpen(false)}
      >
        <DrawerHeader>
          <AccountCircleIcon
            // viewBox={"5 2 10 20"}
            // preserveAspectRatio="none"
            style={{
              width: "75%", //"75px",
              height: "75%", //"75px",
              // cursor: "pointer",
            }}
            // onClick={() => {
            //   router.push(`/`);
            // }}
          />
          <Typography>{profile}</Typography>
        </DrawerHeader>
        <StyledDivider variant="middle" />
        <List>{paths.map((path) => getListItem(path))}</List>
        <StyledDivider variant="middle" />
      </Drawer>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box> */}
    </Box>
  );
};

export default Nav;
