import { ApiResponse } from "../../types/api";
import {
  QueryCountAnswers,
  QueryFrequencyAnswers,
  QueryParams,
} from "../../types/queries";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";

export const queriesClient = () => Axios(`${getApiUrl()}/queries/answers`.replace("/v2",""));

export async function fetchQueriesAnswersFrequency(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryFrequencyAnswers>>("/frequency", {
    params,
  });
}

export async function fetchQueriesAnswersSchoolCount(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryCountAnswers>>("/school/count", {
    params,
  });
}

export async function fetchQueriesAnswersDailyCount(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryCountAnswers>>("/daily/count", {
    params,
  });
}
