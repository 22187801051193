import { isRequired } from "../../consts/required";
import { dontknow, no, unknown, yes, yes_no } from "../../consts/yes_no";

const s1q1 = {
  type: "radiogroup",
  name: "q5s1q1",
  title: {
    default:
      "Has your child had any episodes/times when his/her asthma symptoms were worse than usual?",
    gr: "Είχε το παιδί σας επεισόδια όπου τα συμπτώματα του άσθματος ήταν χειρότερα από ότι συνήθως;",
    fr: "Votre enfant a-t-il/elle eu des épisodes/moments où ses symptômes d’asthme étaient pires que d’habitude ? ",
    de: "",
    ka: "ჰქონდა თუ არა თქვენს შვილს ისეთი ეპიზოდი (ეპიზოდები), როცა ასთმის სიმპტომები ჩვეულებრივზე მეტად გამწვავდა?",
    fi: "Onko lapsellasi ollut jaksoja/kertoja, jolloin hänen astmaoireensa olivat tavallista pahempia?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q2 = { 
  type: "text",
  name: "q5s1q2",
  title: {
    default: "How many times did that happen?",
    gr: "Πόσες φορές συνέβη αυτό;",
    fr: "Combien de fois cela s’est-il produit ?",
    de: "",
    ka: "რამდენჯერ მოხდა ეს?",
    fi: "Kuinka monta kertaa niitä esiintyi?",
  },
  inputType: "number",
  min: 0,
  isRequired: isRequired,
  visibleIf: "{q5s1q1} == 'yes' ",
};

const s1q3 = {
  type: "checkbox",
  name: "q5s1q3",
  visibleIf: "{q5s1q1} == 'yes' ",
  title: {
    default: "What do you think caused this?",
    gr: "Τι νομιζετε ότι το προκάλεσε;",
    fr: "D’après vous, qu’est-ce qui a causé cela ?",
    de: "",
    ka: "თქვენი აზრით, ქვემოთ მოყვანილთაგან რომელი იყო ამის მიზეზი?",
    fi: "Mikä mielestäsi aiheutti tämän? ",
  },
  colCount: 4,
  choices: [
    {
      text: {
        default: "Allergen",
        gr: "Αλλεργία",
        fr: "Allergène",
        de: "",
        ka: "ალერგენები",
        fi: "Allergeeni",
      },
      value: "allergen",
    },
    {
      text: {
        default: "Common cold",
        gr: "Ιώσεις",
        fr: "Rhume",
        de: "",
        ka: "გაციება",
        fi: "Flunssa",
      },
      value: "cold",
    },
    {
      text: {
        default: "Exercise",
        gr: "Άσκηση",
        fr: "Exercice",
        de: "",
        ka: "დატვირთვა",
        fi: "Liikunta",
      },
      value: "exercise",
    },
  ],
  isRequired: isRequired,
  showOtherItem: true,
  separateSpecialChoices: true,
};

const s1q4 = {
  type: "multipletext",
  name: "q5s1q4",
  title: {
    default: "How many days has the child:",
    gr: "Πόσες ημέρες είχε το παιδί σας",
    fr: "Combien de jours l’enfant",
    de: "",
    ka: "თქვენს შვილს რამდენი დღე აღენიშნებოდა:",
    fi: "Kuinka monta päivää lapsella oli",
  },
  isRequired: isRequired,
  items: [
    {
      name: "cough",
      title: {
        default: "Had cough, wheezing, shortness of breath? (days)",
        gr: "Βήχα, Σφύριγμα ή Βράσιμο στο στήθος, Δυσκολία στην αναπνοή; (ημέρες)",
        fr: "A-t-il/elle eu de la toux, une respiration sifflante, un essoufflement ?(jours)",
        de: "",
        ka: "ხველა, ხიხინი, ქოშინი? (დღე)",
        fi: "Yskää, hengityksen vinkumista, hengenahdistusta? (päivää)",
      },
      inputType: "number",
    },
    {
      name: "awakened",
      title: {
        default: "Awakened at night because of symptoms? (times)",
        gr: "Ξύπνησε τη νύχτα λόγω συμπτωμάτων; (φορές)",
        fr: "S’est-il/elle réveillé(e) la nuit à cause de symptômes ? (fois)",
        de: "",
        ka: "ამ სიმპტომების გამო ძილის დარღვევა? (ღამე)",
        fi: "Yöllä heräämistä oireiden takia? (kertaa)",
      },
      inputType: "number",
    },
    {
      name: "exercise",
      title: {
        default: "Had symptoms while exercising or playing? (times)",
        gr: "Είχε συμπτώματα με την άσκηση ή το παιχνίδι; (φορές)",
        fr: "A-t-il/elle eu des symptômes en faisant de l’exercice ou en jouant ? (fois)",
        de: "",
        ka: "სიმპტომები თამაშის ან ვარჯიშის დროს? (-ჯერ)",
        fi: "Oireita liikunnan tai leikkimisen aikana? (kertaa)",
      },
      inputType: "number",
    },
  ],
};

const s1q5 = {
  type: "multipletext",
  name: "q5s1q5",
  title: {
    default: "How many days has your child’s asthma caused him/her to:",
    gr: "Πόσες ημέρες λόγω του άσθματος του παιδιού σας:",
    fr: "À cause de son asthme, combien de jour a-t-il été nécessaire de ",
    de: "",
    ka: "ასთმის გამო რამდენჯერ მოუწია თქვენს შვილს:",
    fi: "Kuinka monta päivää lapsesi astma on aiheuttanut hänelle:",
  },
  isRequired: isRequired,
  items: [
    {
      name: "miss_school",
      title: {
        default: "Miss school? (days)",
        gr: "Απουσίασε από το σχολείο; (ημέρες)",
        fr: "Manquer l’école ? (jours)",
        de: "",
        ka: "სკოლის გაცდენა? (დღე)",
        fi: "Poissaoloa koulusta? (päivää)",
      },
      inputType: "number",
    },
    {
      name: "reduce_activities",
      title: {
        default: "Reduce his/her activities? (days)",
        gr: "Περιόρισε τις δραστηριότητες του; (ημέρες)",
        fr: "Réduire les activités ? (jours)",
        de: "",
        ka: "ფიზიკური აქტივობისგან თავის არიდება? (დღე)",
        fi: "Aktiviteettien vähentymistä? (päivää)",
      },
      inputType: "number",
    },
  ],
};
const s1q6 = {
  type: "radiogroup",
  name: "q5s1q6",
  title: {
    default: "Has the child had any emergency visits for their asthma?",
    gr: "Είχε το παιδί σας λόγω του άσθματος επίσκεψη σε τμήμα επειγόντων περιστατικών ;",
    fr: "L’enfant a-t-il/elle eu des visites d’urgence pour son asthme ? ",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს გადაუდებელი დახმარება ასთმის გამო?",
    fi: "Onko lapsi käynyt päivystyksessä astmansa vuoksi?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};
const s1q7 = {
  type: "radiogroup",
  name: "q5s1q7",
  title: {
    default: "Has the child been hospitalized for their asthma?",
    gr: "Έχει νοσηλευτεί το παιδί σας λόγω του άσθματος;",
    fr: "L’enfant a-t-il été hospitalisé pour son asthme?",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს ჰოსპიტალიზაცია ასთმის გამო?",
    fi: "Onko lapsi joutunut sairaalaan astmansa vuoksi?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1d1 = {
  type: "html",
  name: "subheading",
  html: {
    default: "<p>What treatment has your child used for his/her asthma:</p>",
    gr: "<p>Ποια φάρμακα λαμβάνει το παιδί σας για το άσθμα τους τελευταίους 3μήνες:</p>",
    fr: "<p>Quel traitement votre enfant a-t-il utilisé pour son asthme :</p>",
    de: "<p></p>",
    ka: "<p>რა სახის მკურნალობა უტარდება თქვენს შვილს ასთმის გამო?</p>",
    fi: "<p>Mitä hoitoa lapsesi on käyttänyt astmaansa viimeisten 3 kuukauden aikana?</p>",
  },
};

const s1q8 = {
  type: "radiogroup",
  name: "q5s1q8",
  title: {
    default: "Relievers /rescue treatment (Aerolin/Ventolin):",
    gr: "Θεραπεία Ανακουφιστική/Διάσωσης (Aerolin)",
    fr: "Traitement de secours/crise (Ventoline, Airomir, Ventilastin)",
    de: "",
    ka: "სიმპტომების სწრაფად მოხნისთვის განკუთვნილ საშუალებებს (აეროლინი/ვენტოლონი)",
    fi: "Lievittäjät / varahoito (Aerolin/Ventolin):",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q9 = {
  type: "radiogroup",
  name: "q5s1q9",
  visibleIf: "{q5s1q8} == 'yes' ",
  colCount: 2,
  title: {
    default: "If yes how often:",
    gr: "Εάν ναι πόσο συχνά:",
    fr: "Si oui, avec quelle fréquence",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“ , რამდენად ხშირად იყენებთ ამ საშუალებებს?",
    fi: "Jos kyllä, kuinka usein:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 days/week",
        gr: "≤2 ημέρες/εβδομάδα",
        fr: "≤2 jours/semaine",
        de: "",
        ka: "≤2 დღე/კვირაში",
        fi: "≤2 päivää/viikko",
      },
      value: "less_than_2d_pw",
    },
    {
      text: {
        default: ">2days/week ",
        gr: ">2 ημέρες/εβδομάδα",
        fr: ">2 jours/semaine",
        de: "",
        ka: ">2დღე/კვირაში",
        fi: ">2 päivää/viikko",
      },
      value: "more_than_2d_pw",
    },
  ],
};

const s1q10 = {
  type: "radiogroup",
  name: "q5s1q10",
  title: {
    default: "Daily controller medication:",
    gr: "Καθημερινή θεραπεία ελέγχου/προληπτική",
    fr: "Traitement de fond",
    de: "",
    ka: "სიმპტომების კონტროლისთვის განკუთვნილ ყოველდღიურ საშუალებებს? ",
    fi: "Päivittäinen kontrollilääkitys",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q11 = {
  type: "matrixdropdown",
  name: "q5s1q11",
  visibleIf: "{q5s1q10} == 'yes' ",
  isRequired: isRequired,
  title: {
    default: "If yes, please specify below",
    gr: "Εάν ναι παρακαλώ προσδιορίστε παρακάτω:",
    fr: "Si oui, spécifiez le médicament :",
    de: "",
    ka: "თუ თქვენი პასუხია“კი“,  ქვემოთ მოცემულთაგან მონიშნეთ შესაბამისი მედიკამენტები",
    fi: "Jos vastasitte kyllä, tarkentakaa seuraavaa:",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    {
      name: "daily_dose",
      title: {
        default: "Daily Dose",
        gr: "Καθημερινή δόση",
        fr: "Dose journalière",
        de: "",
        ka: "დღიური დოზა",
        fi: "Vuorokausiannos",
      },
      cellType: "text",
      inputType: "number",
      min: 0,
    },
  ],
  rows: [
    {
      value: "inhaled_corticosteroid",
      text: {
        default: "Inhaled corticosteroid (flixotide/seretide)",
        gr: "Εισπνεόμενα κορτικοστεροειδή (flixotide)",
        fr: "Corticoïde inhalé (Flixotide)",
        de: "",
        ka: "საინჰალაციო კორტიკოსტეროიდები (ფლიქსოტიდი)",
        fi: "Inhaloitava kortikosteroidi (flixotide/seretide)",
      },
    },
    {
      value: "leukotriene",
      text: {
        default: "Leukotriene modifier (modulair/singulair)",
        gr: "Αναστολείς λευκοτριενίων (modulair/singulair)",
        fr: "Anti-Leukotriène (Singulair/Montelukast)",
        de: "",
        ka: "ლეიკოტრიენების მოდიფიკატორები (მოდულეარი/სინგულარი)",
        fi: "Leukotrieenimodifioija (modulair/singulair) ",
      },
    },
    {
      value: "corticosteroids",
      text: {
        default:
          "Combination of corticosteroids and long-acting b2 agonist (seretide, symbicort)",
        gr: "Συνδυασμός κορτικοστεροειδών και β2 διεγέρτων μακράς δράσης (seretide, symbicort, duobreathe)",
        fr: "Association fixe (Seretide, Symbicort, Gibiter)",
        de: "",
        ka: "კორტიკოსტეროიდებისა და გახანგრძლივებული მოქმედების ბეტა2 აგონისტები(სერეტიდი,სიმბიკორტი)",
        fi: "Kortikosteroidien ja pitkävaikutteisen b2-agonistin yhdistelmä (seretide, symbicort)",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλο",
        fr: "Autre",
        de: "",
        ka: "სხვა",
        fi: "Muu",
      },
    },
  ],
};

const s1q12 = {
  type: "matrix",
  name: "q5s1q12",
  isRequired: isRequired,
  title: {
    default: "Has your child had any of the following symptoms?",
    gr: "Είχε το παιδί σας κανένα από τα παρακάτω συμπτώματα από τη μύτη;",
    fr: "Votre enfant a-t-il eu des symptômes de :",
    de: "",
    ka: "აღენიშნებოდა თუ არა თქვენს შვილს ქვემოთ მოყვანილი რომელიმე სიმპტომი?",
    fi: "Oliko lapsellasi jotain seuraavista oireista?",
  },
  columns: [yes, no],
  rows: [
    {
      value: "sneezing",
      text: {
        default: "Sneezing",
        gr: "Συχνά φτερνίσματα",
        fr: "Éternuement",
        de: "",
        ka: "ცემინება",
        fi: "Aivastelu",
      },
    },
    {
      value: "runny_nose",
      text: {
        default: "Runny nose",
        gr: "Συνάχι/Καταρροή",
        fr: "Nez qui coule",
        de: "",
        ka: "სურდო",
        fi: "Vuotava nenä",
      },
    },
    {
      value: "stuffy_nose",
      text: {
        default: "Blocked/Stuffy Nose",
        gr: "Μπούκωμα",
        fr: "Nez bouché",
        de: "",
        ka: "ცხვირის გაჭედვა",
        fi: "Tukkoinen nenä",
      },
    },
    {
      value: "itchy_nose",
      text: {
        default: "Itchy nose",
        gr: "Φαγούρα",
        fr: "Démangeaisons du nez",
        de: "",
        ka: "ცხვირის ქავილი",
        fi: "Kutiseva nenä",
      },
    },
  ],
  alternateRows: true,
  isAllRowRequired: isRequired,
};

const s1q13 = {
  visibleIf:
    "{q5s1q12.sneezing} == 'yes' || {q5s1q12.runny_nose} == 'yes' || {q5s1q12.itchy_nose} == 'yes' || {q5s1q12.stuffy_nose} == 'yes'",
  type: "radiogroup",
  name: "q5s1q13",
  colCount: 2,
  title: {
    default: "Symptoms were present:",
    gr: "Είχε συμπτώματα :",
    fr: "Présence de symptômes : ",
    de: "",
    ka: "სიმპტომები აღენიშნება:",
    fi: "Oireita esiintyi:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4days/week",
        gr: "<4ημέρες/εβδομάδα",
        fr: "<4 jours/semaine",
        de: "",
        ka: "<4 დღე /კვირაში",
        fi: "<4 päivää/viikko",
      },
      value: "less_than_4d_pw",
    },
    {
      text: {
        default: ">4 days/week",
        gr: ">4 ημέρες/εβδομάδα",
        fr: ">4 jours/semaine",
        de: "",
        ka: ">4 დღე/კვირაში",
        fi: ">4 päivää/viikko",
      },
      value: "more_than_4d_pw",
    },
  ],
};
const s1q14 = {
  visibleIf:
    "{q5s1q12.sneezing} == 'yes' || {q5s1q12.runny_nose} == 'yes' || {q5s1q12.itchy_nose} == 'yes' || {q5s1q12.stuffy_nose} == 'yes'",
  type: "radiogroup",
  name: "q5s1q14",
  colCount: 2,
  title: {
    default: "Symptoms were present:",
    gr: "Είχε συμπτώματα:",
    fr: "Présence de symptômes :",
    de: "",
    ka: "სიმპტომები აღენიშნებოდა:",
    fi: "Oireita esiintyi:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4 consecutive weeks",
        gr: "<4 συνεχόμενες εβδομάδες",
        fr: "<4 semaines consécutives	",
        de: "",
        ka: "<4 კვირა ზედიზედ",
        fi: "<4 peräkkäistä viikkoa",
      },
      value: "less_than_4_cw",
    },
    {
      text: {
        default: ">4 consecutive weeks",
        gr: ">4 συνεχόμενες εβδομάδες",
        fr: ">4 semaines consécutives",
        de: "",
        ka: ">4 კვირა ზედიზედ",
        fi: ">4 peräkkäistä viikkoa",
      },
      value: "more_than_4_cw",
    },
  ],
};

const s1q15 = {
  visibleIf:
    "{q5s1q12.sneezing} == 'yes' || {q5s1q12.runny_nose} == 'yes' || {q5s1q12.itchy_nose} == 'yes' || {q5s1q12.stuffy_nose} == 'yes'",
  type: "matrix",
  name: "q5s1q15",
  isRequired: isRequired,
  title: {
    default: "Were symptoms associated with: ",
    gr: "Τα συμπτώματα σχετίζονταν με: ",
    fr: "Les symptômes étaient-ils associés à :",
    de: "",
    ka: "იყო თუ არა სიმპტომების არსებობა ასოციირებული შემდეგთან:",
    fi: "Aiheutuiko oireista seuraavia?",
  },
  columns: [yes, no, dontknow],
  rows: [
    {
      value: "sleep_disturbance",
      text: {
        default: "Sleep disturbance:",
        gr: "Διαταραχές στον ύπνο:",
        fr: "Trouble du sommeil ? ",
        de: "",
        ka: "ძილის დარღვევა?",
        fi: "Unihäiriöt:",
      },
    },
    {
      value: "impairment_of_daily_activities",
      text: {
        default: "Impairment of daily activities, leisure and/or sport:",
        gr: "Περιορισμό στις καθημερινές δραστηριότητες ή/και στην άσκηση:",
        fr: "Altération des activités quotidiennes, des loisirs et/ou du sport ?",
        de: "",
        ka: "ყოველდღიური აქტივობებსიგან (სპორტი, გართობა) თავის არიდება? ",
        fi: "Päivittäisen toiminnan, vapaa-ajan ja/tai urheilun heikentyminen:",
      },
    },
    {
      value: "impairment_of_school",
      text: {
        default: "Impairment of school:",
        gr: "Περιορισμό στο σχολείο:",
        fr: "Altération de l’activité à l’école ? ",
        de: "",
        ka: "სკოლის გაცდენა?",
        fi: "Koulun heikkeneminen: ",
      },
    },
  ],
  alternateRows: true,
  isAllRowRequired: true,
};

const s1q16 = {
  type: "radiogroup",
  name: "q5s1q16",
  title: {
    default:
      "Has your child received any treatment for allergic rhinitis, in the last 3 months:",
    gr: "Έχει λάβει το παιδί σας οποιαδήποτε θεραπεία για αλλεργική ρινίτιδα, τους τελευταίους 3 μήνες:",
    fr: "Votre enfant a-t-il reçu des traitements pour la rhinite allergique aux cours des dernier 3 mois ?",
    de: "",
    ka: "ბოლო 3 თვის განმავლობაში ხომ არ მიუღია თქვენს შვილს ალერგიული რინიტის სამკურნალო საშუალებები:",
    fi: "Onko lapsesi saanut hoitoa allergiseen nuhaan viimeisen 3kk aikana:",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q16a = {
  type: "multipletext",
  name: "q5s1q16a",
  visibleIf: "{q5s1q16} == 'yes'",
  title: {
    default: "If yes, on average how many days has your child received:",
    gr: "Αν ναι, πόσες μέρες κατά μέσο όρο έλαβε το παιδί σας:",
    fr: "Si oui, combien de jour a-t-il/elle été traité(e) par",
    de: "",
    ka: "ანტიჰისტამინური პრეპარატები [დღე]",
    fi: "antihistamiinia suun kautta [päivänä]",
  },
  isRequired: isRequired,
  items: [
    {
      name: "oral_antistamines",
      title: {
        default: "Oral antihistamines [days]",
        gr: "Αντιισταμινικά από το στόμα (σιρόπι, χάπια) [μέρες]",
        fr: "Des antihistaminiques (jours)",
        de: "",
        ka: "ანტიჰისტამინური პრეპარატები [დღე]",
        fi: "antihistamiinia suun kautta [päivänä]",
      },
      inputType: "number",
    },
    {
      name: "nasal_sprays",
      title: {
        default: "Nasal sprays (Intranasal corticosteroids) [days]",
        gr: "Σπρεί για τη μύτη (ενδορινικά κορτικοστεροειδή) [μέρες]",
        fr: "Des sprays nasaux (corticoïdes nasaux) [jours]",
        de: "",
        ka: "ნაზალური სპრეი (ინტრანაზალური კორტიკოსტეროიდები) [დღე]",
        fi: "nenäsuihketta allergiseen nuhaan [päivänä]",
      },
      inputType: "number",
    },
  ],
};

const s1q17 = {
  type: "matrixdropdown",
  name: "q5s1q17",
  isRequired: isRequired,
  title: {
    default: "Has the child had any infections in the last three months?",
    gr: "Είχε το παιδί σας καμία από τις παρακάτω λοιμώξεις;",
    fr: "L’enfant a-t-il/elle eu des infections ?",
    de: "",
    ka: "აღენიშნებოდა თუ არა თქვენს შვილს რაიმე სახის ინფექცია?",
    fi: "Onko lapsella ollut infektioita?",
  },
  horizontalScroll: true,
  // columnMinWidth: "130px",
  columns: [
    yes_no,
    {
      name: "infection_times",
      title: {
        default: "Times",
        gr: "Πόσες φορές",
        fr: "Combien d’épisodes",
        de: "",
        ka: "რამდენჯერ",
        fi: "Kuinka monta kertaa",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 20,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 99 to 20",
          minValue: 1,
          maxValue: 20,
        },
      ],
    },
    {
      name: "infection_max_duration",
      title: {
        default: "Maximum Duration (days)",
        gr: "Μέγιστη διάρκεια (ημέρες)",
        fr: "Durée maximale (jours)",
        de: "",
        ka: "მაქს.ხანგრძლივობა(დღეები)",
        fi: "Pisin kesto",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 99,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 99",
          minValue: 1,
          maxValue: 99,
        },
      ],
    },
  ],
  rows: [
    {
      value: "up_resp_infections",
      text: {
        default: "Upper respiratory infections (common colds)",
        gr: "Λοιμώξεις ανώτερου αναπνευστικού (κοινά κρυολογήματα)",
        fr: "Infections des voies respiratoires supérieures (rhumes)",
        de: "",
        ka: "ზედა სასუნთქი გზების ინფექცია (გაციება)",
        fi: "Ylempien hengitysteiden infektiot (flunssat)",
      },
    },
    {
      value: "lower_resp_infections",
      text: {
        default: "Lower respiratory infections (pneumonia, bronchitis)",
        gr: "Λοιμώξεις κατώτερου αναπνευστικού (πνευμονία, βρογχίτιδα)",
        fr: "Infections des voies respiratoires inférieures (pneumopathie, bronchite)",
        de: "",
        ka: "ქვედა სასუნთქი გზების ინფექცია (პნევმონია, ბრონქიტი, გულმკერდის სხვა ინფექციები)",
        fi: "Alempien hengitysteiden infektiot (keuhkokuume, keuhkoputkentulehdus).",
      },
    },
    {
      value: "otitis",
      text: {
        default: "Otitis",
        gr: "Ωτίτιδα",
        fr: "Otite",
        de: "",
        ka: "ყურის ინფექცია",
        fi: "Korvatulehdus",
      },
    },
    {
      value: "gastroenteritis",
      text: {
        default: "Gastroenteritis (diarrhea/vomiting)",
        gr: "Γαστρεντερίτιδα (Διάρροια/Εμετός) ",
        fr: "Gastro-entérite (diarrhée/vomissements)",
        de: "",
        ka: "გასტროენტერიტი (დიარეა/ღებინება)",
        fi: "Gastroenteriitti (ripuli/oksentelu)",
      },
    },
    {
      value: "coronavirus",
      text: {
        default: "Coronavirus infection (COVID-19)",
        gr: "Λοίμωξη από κοροναϊό (COVID-19)",
        fr: "Infection à COVID-19",
        de: "",
        ka: "Covid ინფექცია",
        fi: "Koronavirus",
      },
    },
    {
      value: "staphylococcus",
      text: {
        default: "Staphylococcus diagnosed by nasal swab",
        gr: "Σταφυλόκοκκος που διαγνώστηκε με εξέταση από τη μύτη (ρινικό επίχρισμα)",
        fr: "Staphylocoque diagnostiqué par prélèvement nasal",
        de: "",
        ka: "სტაფილოკოკი, რომელიც დიაგნოზირებულია ცხვირის ნაცხის საშუალებით",
        fi: "Nenäpuikolla diagnosoitu stafylokokki",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλες",
        fr: "Autres:",
        de: "",
        ka: "სხვა",
        fi: "Muu",
      },
    },
  ],
};

const s1q17a = {
  type: "text",
  name: "q5s1q17a",
  visibleIf: "{q5s1q17.other.yes_no} == 'yes' ",
  title: {
    default: "Please describe:",
    gr: "Παρακαλώ περιγράψτε:",
    fr: "Veuillez décrire:",
    de: "",
    ka: "თხოვთ, დააკონკრეტოთ:",
    fi: "Ole hyvä ja tarkenna:",
  },
  maxLength: 100,
  isRequired: isRequired,
};

const s1q18 = {
  type: "radiogroup",
  name: "q5s1q18",
  title: {
    default: "Has your child had any unscheduled visits to a doctor?",
    gr: "Είχε το παιδί σας καμιά μη προγραμματισμένη επισκέψη στο γιατρό;",
    fr: "Votre enfant a-t-il eu des visites imprévues chez le médecin ? ",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს ექიმთად არაგეგმიური ვიზიტი?",
    fi: "Onko lapsellasi ollut suunnittelemattomia lääkärikäyntejä? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q19 = {
  name: "q5s1q19",
  type: "text",
  title: {
    default: "If yes, for what reason?",
    gr: "Εάν ναι για ποιο λόγο;",
    fr: "Si oui, pour quelle raison ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რა იყო ამის მიზეზი?",
    fi: "Jos kyllä, mistä syystä?",
  },
  maxLength: 50,
  isRequired: isRequired,
  visibleIf: "{q5s1q18} == 'yes' ",
};

const s1q20 = {
  type: "radiogroup",
  name: "q5s1q20",
  title: {
    default: "Have there been any changes in your home environment?",
    gr: "Εχει γίνει καμιά αλλαγή στο περιβάλλον του σπιτιού σας;",
    fr: "Y a-t-il eu des changements dans votre environnement familial ? ",
    de: "",
    ka: "ადგილი ჰქონდა თუ არა, რაიმე ცვლილებას თქვენს საცხოვრებელ გარემოში? ",
    fi: "Onko kotiympäristössäsi tapahtunut muutoksia? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q21 = {
  type: "radiogroup",
  name: "q5s1q21",
  title: {
    default: "Are there new pets or new contact with pets elsewhere?",
    gr: "Υπάρχουν καινούργια ζώα στο σπίτι ή επαφή με ζώα κάπου αλλού;",
    fr: "Y a-t-il de nouveaux animaux de compagnie ou de nouveaux contacts avec des animaux de compagnie ailleurs ?",
    de: "",
    ka: "ხომ არ გაიჩინეთ ახალი შინაური ცხოველები ან გქონიათ სადმე ახალი კონტაქტი შინაურ ცხოველებთან?",
    fi: "Onko muualla uusia lemmikkejä tai uusia kontakteja lemmikkeihin?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q21a = {
  type: "checkbox",
  name: "q5s1q21a",
  title: {
    default: "If yes, which pets? ",
    gr: "Αν ναι, ποια ζώα;",
    fr: "Si oui, quels animaux ?",
    de: "",
    ka: "თუ კი, რომელი შინაური ცხოველები?",
    fi: "Jos kyllä, mitkä lemmikit?",
  },
  isRequired: isRequired,
  colCount: 5,
  visibleIf: "{q5s1q21} == 'yes' ",
  choices: [
    {
      value: "dog",
      text: {
        default: "Dog",
        gr: "Σκύλος",
        fr: "Chien",
        de: "",
        ka: "ძაღლი",
        fi: "Koira",
      },
    },
    {
      value: "cat",
      text: {
        default: "Cat",
        gr: "Γάτα",
        fr: "Chat",
        de: "",
        ka: "კატა",
        fi: "Kissa",
      },
    },
    {
      value: "other_furry_pets",
      text: {
        default: "Other furry pets",
        gr: "Άλλα κατοικίδια ζώα με τρίχωμα",
        fr: "Autres animaux à fourrure",
        de: "",
        ka: "სხვა ბეწვიანი ცხოველები",
        fi: "Muita karvaisia lemmikkejä",
      },
    },
    {
      value: "bird",
      text: {
        default: "Bird",
        gr: "Πτηνό",
        fr: "Oiseau",
        de: "",
        ka: "ჩიტები",
        fi: "Lintu",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλα",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muita",
      },
    },
  ],
};

const s1q22 = {
  type: "radiogroup",
  name: "q5s1q22",
  title: {
    default:
      "Is there any intense mold or humidity in the house where your child is currently living?",
    gr: "Υπάρχει έντονη μούχλα ή υγρασία στο σπίτι που μένει το παιδί σας αυτήν την περίοδο;",
    fr: "Y a-t-il de la moisissure ou de l’humidité intense dans la maison où vit actuellement votre enfant ?",
    de: "",
    ka: "სახლში, სადაც თქვენი შვილი ცხოვრობს, აღინიშნება თუ არა ობის ან ნესტის უბნების არსებობა?",
    fi: "Onko talossa, jossa lapsesi asuu tällä hetkellä, voimakasta hometta tai kosteutta?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q22a = {
  type: "matrixdropdown",
  name: "q5s1q22a",
  title: {
    default: "If yes, symptoms worsen of:",
    gr: "Εάν ναι επιδεινώνθηκαν τα συμπτώματα:",
    fr: "Si oui, aggravation des symptômes de :",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ამწვავებს თუ არა ეს შემდეგ სიმპტომებს:",
    fi: "Jos vastasitte kyllä:",
  },
  isRequired: isRequired,
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [yes_no],
  rows: [
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Του άσθματος",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astmaa",
      },
    },
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic Rhinitis",
        gr: "Της αλλεργικής ρινίτιδας",
        fr: "Rhinite allergique",
        de: "",
        ka: "ალერგიული რინიტი:",
        fi: "Allergisen nuhan oireiden paheneminen",
      },
    },
  ],
  visibleIf: "{q5s1q22} == 'yes' ",
};
const s1q23 = {
  type: "radiogroup",
  name: "q5s1q23",
  title: {
    default: "Changes in the smoking status of the family?",
    gr: "Υπάρχουν αλλαγές όσον αφορά το κάπνισμα στην οικογένεια;",
    fr: "Y a-t-il eu des changements dans le statut tabagique de la famille ? ",
    de: "",
    ka: "შეიცვალა თუ არა თქვენს ოჯახში სიგარეტის მოხმარების სტატუსი?",
    fi: "Muutoksia perheen tupakointitilanteessa? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q23a = {
  type: "radiogroup",
  name: "q25s1q23a",
  title: {
    default: "If yes, has someone stopped smoking? ",
    gr: "Εάν ναι, έχει κάποιος διακόψει το κάπνισμα;",
    fr: "Si oui, quelqu'un a-t-il arrêté de fumer ?",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ოჯახის რომელიმე  წევრმა შეწყვიტა სიგარეტის მოწევა?",
    fi: "Jos kyllä, onko joku lopettanut tupakoinnin?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q5s1q23} == 'yes' ",
};

const s1q23b = {
  type: "radiogroup",
  name: "q5s1q23b",
  title: {
    default: "Has someone started smoking in the house?",
    gr: "Έχει κάποιος ξεκινήσει το κάπνισμα στο σπίτι;",
    fr: "Quelqu'un a-t-il commencé à fumer dans la maison ? ",
    de: "",
    ka: "ოჯახის რომელიმე წევრმა დაიწყო სახლში სიგარეტის მოწევა?",
    fi: "Onko joku aloittanut tupakoinnin talossa? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q5s1q23} == 'yes' ",
};
const s1q24 = {
  type: "radiogroup",
  name: "q5s1q24",
  title: {
    default: "Moved to another residence?",
    gr: "Έχετε μετακομίσει σε καινούργιo τόπο κατοικίας;",
    fr: "Avez-vous déménagé dans une autre résidence ?",
    de: "",
    ka: "ხომ არ შეგიცვლიათ საცხოვრებელი ადგილი?",
    fi: "Onko lapsi muuttanut toiseen asuinpaikkaan?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};
export const elementsS1 = [
  s1q1,
  s1q2,
  s1q3,
  s1q4,
  s1q5,
  s1q6,
  s1q7,
  s1d1,
  s1q8,
  s1q9,
  s1q10,
  s1q11,
  s1q12,
  s1q13,
  s1q14,
  s1q15,
  s1q16,
  s1q16a,
  s1q17,
  s1q17a,
  s1q18,
  s1q19,
  s1q20,
  s1q21,
  s1q21a,
  s1q22,
  s1q22a,
  s1q23,
  s1q23a,
  s1q23b,
  s1q24,
];
