import { questionnaire1 } from "./questionnaire1/questionnaire1";
import { questionnaire1_old } from "./questionnaire1_old/questionnaire1";
import { questionnaire2 } from "./questionnaire2/questionnaire2";
import { questionnaire3 } from "./questionnaire3/questionnaire3";
import { questionnaire4 } from "./questionnaire4/questionnaire4";
import { questionnaire4_old } from "./questionnaire4_old/questionnaire4";
import { questionnaire5 } from "./questionnaire5/questionnaire5";
import { questionnaire6 } from "./questionnaire6/questionnaire6";

export const questionnaires = [
  questionnaire1_old,
  questionnaire1,
  questionnaire2,
  questionnaire3,
  questionnaire4_old,
  questionnaire4,
  questionnaire5,
  questionnaire6,
];

// export const parentTemplateClient = () => Axios(`${getApiUrl()}/parent/template/questionnaire`);

// export async function updateUser(values: any) {
//   return await parentTemplateClient().put("", values);
// }
