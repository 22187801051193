import { CreatePayload, Student, Teacher, User } from "../../types/user";
import { ApiResponse, UserApiData } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { ParentQuestionnaireAnswers } from "../../types/questionnaire";
import { getHealthTags } from "../../utils/tags-functions";
import { getRole, getUsername } from "./utils.api";

export const userClient = () => Axios(`${getApiUrl()}/user`);
export const studentClient = () => Axios(`${getApiUrl()}/student`);

export async function postHealthConditionTags(
  data: ParentQuestionnaireAnswers
) {
  const tags = getHealthTags(data);
  const username = getUsername();
  const role = getRole();
  const payload = {
    username,
    tags,
    role,
  };
  return userClient().put("/update", payload);
}

export async function createNewUser(values: CreatePayload<User>) {
  return await userClient().post("/create", values);
}

export async function updateUser(values: User) {
  return await userClient().put("/update", values);
}

export async function updateUserMany(
  values: Pick<Student, "registration_code" | "offline_code" | "schools">[]
) {
  return await userClient().post("/update/many", { users: values });
}

export async function deleteUser(values: User) {
  return await userClient().delete("/delete", {
    params: { username: values._id, role: values.role, soft: true },
  });
}

export async function deleteUserPerm(values: User) {
  return await userClient().delete("/delete", {
    params: { username: values._id, role: values.role, soft: false },
  });
}

export async function fetchUsers() {
  return userClient().get<ApiResponse<UserApiData>>("/get/all");
}

// export async function fetchStudents() {
//   return studentClient().get<ApiResponse<Student[]>>("/get/all");
// }

export async function fetchStudentByCode(code: string) {
  return studentClient().get<ApiResponse<Student>>("/get", {
    params: { offline_code: code },
  });
}

export async function fetchTeacher(id: string) {
  return userClient().get<ApiResponse<Teacher>>("/get", {
    params: { username: id, role: "teacher" },
  });
}

export async function generateStudents(school: string, students_num: number) {
  return await studentClient().post("/generate", { school, students_num });
}

export async function findStudentsByUsername(data: string) {
  return await studentClient().get(`/find/${data}`);
}
