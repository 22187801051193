"use client";
import {
  Box,
  CircularProgress,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import {
  Model,
  StylesManager,
  SurveyModel,
  surveyLocalization,
} from "survey-core";
import { Survey } from "survey-react-ui";
import styles from "../../styles/Survey.module.scss";
// import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.i18n";
import { StyledFormControl } from "../styled/StyledFormSelect";

import {
  ParentAnswers,
  ParentQuestionnaireAnswers,
  TemplateParentQuestionnaire,
} from "../../types/questionnaire";
import { StyledButton } from "../styled/Button";
import { registerSurveyListeners } from "../../utils/surveyHelper";
import { useNavigate } from "react-router-dom";
import { theme } from "../../consts/theme";
import { useAppThunkDispatch } from "../../store/hooks";
import { getDrafts } from "../../store/effects/questionnaireEffects";
import { getRole } from "../../services/api/utils.api";
import { ROLES } from "../../types/user";
import { questionnaire1 } from "../../content/questionnaire1/questionnaire1";
import { questionnaire4 } from "../../content/questionnaire4/questionnaire4";
import { questionnaire2 } from "../../content/questionnaire2/questionnaire2";
import { questionnaire3 } from "../../content/questionnaire3/questionnaire3";
import { questionnaire5 } from "../../content/questionnaire5/questionnaire5";
import { questionnaire6 } from "../../content/questionnaire6/questionnaire6";
import Loading from "../Loading";

// Override individual translations in an existing locale:
// const engLocale = surveyLocalization.locales["en"];
// engLocale.pagePrevText = "Back";
// engLocale.pageNextText = "Forward";
// engLocale.completeText = "Send";

// ... or add a custom locale
// const customLocaleStrings = {
//     pagePrevText: "Προηγούμενο",
//     pageNextText: "Επόμενο",
//     completeText: "Ολοκλήρωση"
// };
// surveyLocalization.locales["gr"] = customLocaleStrings;

// saving changes
// const titleLoc : TitleLoc = {
//   en: "Saving Changes",
//   gr: "Αποθήκευση αλλαγών",
//   fr: "Enregistrer les modifications",
//   ka: "ცვლილებების შენახვა",
//   fi: "Muutosten tallentaminen"
// }

const SurveyComponent = memo(
  ({
    questionnaire,
    answers,
    saveData,
  }: {
    questionnaire: TemplateParentQuestionnaire;
    answers: ParentAnswers;
    saveData?: (
      data: ParentQuestionnaireAnswers,
      draft: boolean
    ) => Promise<boolean>;
  }) => {
    // const [survey, setSurvey] = useState<SurveyModel | undefined>();
    const [survey, setSurvey] = useState(new Model(questionnaire));
    // const [survey, setSurvey] = useState(new Model(questionnaire4));
    const [lan, setLan] = useState("en");
    StylesManager.applyTheme("defaultV2");

    const [showBackButton, setShowBackButton] = useState(false);
    const [completed, setCompleted] = useState(false);
    const router = useNavigate();
    const [flag, setFlag] = useState(true);

    // console.log("FLAG: ", flag);
    // console.log(questionnaire1);

    // useEffect(() => {
    //   if (questionnaire._id === "0") {
    //     const updatedQuestionnaire = {
    //       ...questionnaire,
    //       title: "TEST",
    //       pages: questionnaire.pages.map((page, index) => {
    //         if (index === 0) {
    //           return {
    //             ...page,
    //             elements: page.elements.map((element, index) => {
    //               if (index === 0) {
    //                 return {
    //                   ...element,
    //                   title: "TEST",
    //                 };
    //               }
    //               return { ...element };
    //             }),
    //           };
    //         }
    //         return { ...page };
    //       }),
    //     };
    //     setSurvey(new Model(updatedQuestionnaire));
    //     console.log(updatedQuestionnaire.pages);
    //   } else {
    //     setSurvey(new Model(questionnaire));
    //   }
    // }, []);

    useEffect(() => {
      if (survey && saveData) {
        survey.data = answers;
        registerSurveyListeners(
          survey,
          questionnaire._id,
          saveData,
          setShowBackButton,
          setCompleted,
          setFlag,
          lan
        );
      }
    }, [survey]);

    function handleRefresh(e: any) {
      e.stopImmediatePropagation();
      // console.log("REFRESH FLAG: ", flag);

      if (flag) {
        return undefined;
      }

      // Cancel the event
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.preventDefault();

      // Chrome requires returnValue to be set
      return (e.returnValue =
        "You have unsaved changes. Are you sure you want to exit?");
    }

    useEffect(() => {
      window.addEventListener("beforeunload", handleRefresh);

      return () => {
        window.removeEventListener("beforeunload", handleRefresh);
      };
    }, [flag]);

    // survey.css = customCss;

    const changeLan = (e: SelectChangeEvent) => {
      setLan(e.target.value);
      if (survey) survey.locale = e.target.value;
    };

    // Render the survey
    return (
      <Container className={styles.surveyContainer} maxWidth='xl'>
        <Box
          sx={{
            minWidth: 120,
            marginBottom: "10px",
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "14px",
            gap: "5px",
          }}
        >
          {flag ? (
            <Typography sx={{ fontSize: "14px" }}>Changes Saved!</Typography>
          ) : (
            <>
              <ThemeProvider theme={theme}>
                <CircularProgress size={18} thickness={4} />
              </ThemeProvider>
              <Typography sx={{ fontSize: "14px" }}>
                Saving Changes...
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            minWidth: 120,
            marginBottom: "10px",
          }}
        >
          <StyledFormControl fullWidth>
            <InputLabel id='select-lang'>Language</InputLabel>
            <Select
              labelId='lan'
              id='lan'
              value={lan}
              label='Language'
              onChange={(e) => changeLan(e)}
              size='small'
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"fi"}>Suomalainen</MenuItem>
              <MenuItem value={"fr"}>Français</MenuItem>
              <MenuItem value={"ka"}>ქართული</MenuItem>
              <MenuItem value={"gr"}>Ελληνικά</MenuItem>
            </Select>
          </StyledFormControl>
        </Box>
        {survey && <Survey model={survey} />}{" "}
        {completed &&
          (showBackButton ? (
            <StyledButton
              sx={{ fontSize: "16px", marginTOp: 1 }}
              variant='contained'
              type='button'
              onClick={() => {
                router("/dashboard/questionnaires");
              }}
            >
              Go Back
            </StyledButton>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
              marginTop={4}
            >
              <ThemeProvider theme={theme}>
                <CircularProgress size={30} thickness={5} />
                <Typography sx={{ fontSize: "14px" }}>
                  Please do not close your browser while we are saving your
                  survey.Thank you!
                </Typography>
              </ThemeProvider>
            </Box>
          ))}
      </Container>
    );
  }
);

export default SurveyComponent;
