import { isNotRequired, isRequired } from "./required";

export const yes = {
  value: "yes",
  text: {
    default: "Yes",
    gr: "Ναι",
    fr: "Oui",
    de: "",
    ka: "კი",
    fi: "Kyllä",
  },
};

export const no = {
  value: "no",
  text: {
    default: "Νο",
    gr: "Όχι",
    fr: "Non",
    de: "",
    ka: "არა",
    fi: "Ei",
  },
};

export const yes_no = {
  name: "yes_no",
  title: {
    default: "Yes/No",
    gr: "Ναι/Όχι",
    fr: "Oui/Non",
    de: "",
    ka: "კი/არა",
    fi: "Kyllä/Ei",
  },
  choices: [yes, no],
  colCount: 2,
  cellType: "radiogroup",
  isRequired: isRequired,
};

export const yes_no_notRequired = {
  name: "yes_no",
  title: {
    default: "Yes/No",
    gr: "Ναι/Όχι",
    fr: "Oui/Non",
    de: "",
    ka: "კი/არა",
    fi: "Kyllä/Ei",
  },
  choices: [yes, no],
  colCount: 2,
  cellType: "radiogroup",
  isRequired: isNotRequired,
};

export const dontknow = {
  value: "dont_know",
  text: {
    default: "I don’t know",
    gr: "Δε γνωρίζω",
    fr: "Je ne sais pas",
    de: "",
    ka: "არ აღენიშნება", //არ ვიცი
    fi: "En tiedä",
  },
};

export const nosymptoms = {
  text: {
    default: "No symptoms",
    gr: "Χωρίς συμπτώματα",
    fr: "Pas de symptôme",
    de: "",
    ka: "სიმპტომები არ აღინიშნება",
    fi: "Ei oireita",
  },
  value: "no_symptoms",
};

export const unknown = {
  value: "unknown",
  text: {
    default: "Unknown",
    gr: "Δε γνωρίζω",
    fr: "",
    de: "",
    ka: "",
    fi: "",
  },
};

export const symptomsChoices = [
  {
    text: {
      default: "Not at all",
      gr: "Καμία",
      fr: "Jamais",
      de: "",
      ka: " საერთოდ არ ჰქო",
      fi: "Ei yhtenäkään",
    },
    value: 5,
  },
  {
    text: {
      default: "1-3 days/month",
      gr: "1-3 ημέρες",
      fr: "1 à 3 jours",
      de: "",
      ka: "1-3 დღე/თვეში",
      fi: "1–3 päivänä",
    },
    value: 4,
  },
  {
    text: {
      default: "4-10 days/month",
      gr: "4-10 ημέρες",
      fr: "4 à 10 jours",
      de: "",
      ka: "4-10 დღე/თვე",
      fi: "4–10 päivänä",
    },
    value: 3,
  },
  {
    text: {
      default: "11-18 days/month",
      gr: "11-18 ημέρες",
      fr: "11 à 18 jours",
      de: "",
      ka: "11-18 დღე/თვე",
      fi: "11–18 päivänä",
    },
    value: 2,
  },
  {
    text: {
      default: "19-24 days/month",
      gr: "19-24 ημέρες",
      fr: "19 à 24 jours",
      de: "",
      ka: "19-24 დღე/თვეშ",
      fi: "19–24 päivänä",
    },
    value: 1,
  },
  {
    text: {
      default: "Everyday",
      gr: "Κάθε μέρα",
      fr: "Tous les jours",
      de: "",
      ka: "ყოველდღე",
      fi: "joka päivä",
    },
    value: 0,
  },
];
