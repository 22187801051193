import {
  useState,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useEffect,
} from "react";
import * as XLSX from "xlsx";
import styles from "../styles/Upload.module.scss";
import { Container } from "@mui/material";
import { StyledButton } from "./styled/Button";
import { getSchools } from "../store/effects/schoolEffects";
import { useAppSelector, useAppThunkDispatch } from "../store/hooks";
import { selectSchools } from "../store/selectors/schoolSelectors";
import { selectUsers } from "../store/selectors/userSelector";
import { updateUserMany } from "../services/api/users.api";
import { ROLES } from "../types/user";
import { getUsers } from "../store/effects/userEffects";
import sample from "../assets/sampleUp.png";
import AlertDialog from "./Alert";
import { handleApiError } from "../utils/handleApiError";

type UploadData = {
  registration_code: string;
  unique_id: string;
  school_name: string;
};

type UploadDataApi = {
  registration_code: string;
  offline_code: string;
  school_id: string;
};

const Upload = () => {
  const dispatch = useAppThunkDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [items, setItems] = useState<UploadData[]>([]);
  const schools = useAppSelector(selectSchools);
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();

  // const router = useRouter();

  useEffect(() => {
    dispatch(getSchools());
  }, [dispatch]);

  const onFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;

    if (!fileInput.files || fileInput.files.length === 0) {
      setError("No file was chosen");
      setFile(null);
      setItems([]);
      return;
    }

    const file = fileInput.files[0];

    /** File validation to check*/

    /** Setting file state */
    setFile(file); // we will use the file state, to send it later to the server

    // /** Reset file input */
    // e.currentTarget.type = "text";
    // e.currentTarget.type = "file";

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        if (e.target !== null) {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, {
            type: "buffer",
          });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          // console.log(data);
          resolve(data);
        }
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise
      .then((d: any) => {
        setItems(d);
      })
      .catch((e) => {
        handleApiError(e, setError);
      });
  };

  const onUploadFile = (e: SyntheticEvent) => {
    e.preventDefault();
    setAlert("Are you sure?");
  };

  const uploadFile = () => {
    console.log("Upload");
    const data = items.map((item) => {
      const school = schools.find(
        (school) => item.school_name === school.title
      );
      return {
        registration_code: item.registration_code.toString(),
        offline_code: item.unique_id,
        schools: [school ? school.school_id! : ""],
        role: ROLES.STUDENT,
      };
    });
    console.log(data);
    updateUserMany(data)
      .then((res) => {
        console.log(res);
        // alert(res.message);
        // router.back();
      })
      .catch((e) => {
        handleApiError(e, setError);
      });
  };

  return (
    <Container className={styles.container} maxWidth='xl'>
      <h1>Upload your file</h1>
      <form action='' onSubmit={(e) => onUploadFile(e)}>
        <label>
          <strong>Select a file:</strong>
          <input name='file' type='file' onChange={onFileUploadChange} />
        </label>
        <StyledButton variant='contained' disabled={!file} type='submit'>
          Upload data
        </StyledButton>
      </form>
      {file !== null ? (
        <div className={styles.preview}>
          <h3>The Data of The Uploaded Excel Sheet</h3>
          <table>
            <thead>
              <tr>
                <th>Registration Codes</th>
                <th>Unique Ids</th>
                <th>School Name</th>
              </tr>
            </thead>
            <tbody>
              {items.map((data, index) => (
                <tr key={index}>
                  <td>{data["registration_code"]}</td>
                  <td>{data["unique_id"]}</td>
                  <td>{data["school_name"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.preview}>
          <h3>
            The data of the uploaded <strong>Excel Sheet</strong> or{" "}
            <strong>CSV file</strong> must be in the following format:
          </h3>
          <img src={sample} width={500} height={250} alt='Sample Excel' />
          <figcaption>
            The first column will be the registration codes of the students. The
            second column will be the unique codes of the students. The third
            column will be the school name the student is attending. The first
            row must be the in the format shown in the picture
            (registration_code, unique_id, school_name).
          </figcaption>
        </div>
      )}
      {error ||
        (alert && (
          <AlertDialog
            title={error ? "System Error" : "System Information"}
            message={error || alert || ""}
            buttons={
              error
                ? [
                    {
                      label: "Ok",
                      onClick: () => {
                        setError(undefined);
                      },
                    },
                  ]
                : [
                    {
                      label: "Ok",
                      onClick: () => {
                        uploadFile();
                        setAlert(undefined);
                      },
                    },
                    {
                      label: "Cancel",
                      onClick: () => {
                        setAlert(undefined);
                      },
                      outlined: true,
                    },
                  ]
            }
          />
        ))}
    </Container>
  );
};

export default Upload;
