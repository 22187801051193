import { createReducer } from "@reduxjs/toolkit";
import {
  getBarChartQuestionnaires,
  getLineChartQuestionnaires,
  getNoAnswersStudents24h,
  getNoAnswersStudentsTable,
} from "../effects/reportingEffects";
import {
  QueryFrequencyAnswers,
  QueryCountAnswers,
  StudentsNoAnswerTable,
  FrequencyObject,
} from "../../types/queries";
import { handleApiError } from "../../utils/handleApiError";
import { REPORTING_ACTIONS } from "../actions/reportingActions";

export interface ReportingState {
  lineChartData: FrequencyObject;
  barChartQuestionnairesData: QueryCountAnswers;
  tableNoAnswersStudentsData: StudentsNoAnswerTable[];
  noAnswersStudentsData24h: { answered: string; notAnswered: string };
  error: string | undefined;
}
const initQueryAnswers = {
  "Not answered": [],
  Answered: {},
  School: null,
  Country: null,
};

const initialState: ReportingState = {
  lineChartData: {},
  barChartQuestionnairesData: { Answered: {}, "Not answered": {} },
  tableNoAnswersStudentsData: [],
  noAnswersStudentsData24h: { answered: "", notAnswered: "" },
  error: undefined,
};

const reportingReducer = createReducer(initialState, (builder) => {
  builder.addCase(getNoAnswersStudentsTable.fulfilled, (state, { payload }) => {
    console.log("Payload Table: ", payload);
    // console.log("Payload Table 2: ", payload["Not answered"]);

    return { ...state, tableNoAnswersStudentsData: payload["Not answered"] };
  });
  builder.addCase(getNoAnswersStudentsTable.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(getNoAnswersStudents24h.fulfilled, (state, { payload }) => {
    // console.log("Payload: ", payload);
    const values = Object.values(payload.Answered);
    const answered = values
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0)
      .toString();
    const notAnswered = payload["Not answered"].length.toString();
    return {
      ...state,
      noAnswersStudentsData24h: {
        answered: answered,
        notAnswered: notAnswered,
      },
    };
  });
  builder.addCase(getNoAnswersStudents24h.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(getBarChartQuestionnaires.fulfilled, (state, { payload }) => {
    // console.log("Payload Bar Chart: ", payload["Answered"]);
    return { ...state, barChartQuestionnairesData: payload };
  });
  builder.addCase(getBarChartQuestionnaires.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(
    getLineChartQuestionnaires.fulfilled,
    (state, { payload }) => {
      // console.log("Payload Line Chart: ", payload["Answered"]);
      return { ...state, lineChartData: payload["Answered"] };
    }
  );
  builder.addCase(getLineChartQuestionnaires.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(REPORTING_ACTIONS.CLEAR_ERROR, (state) => {
    return {
      ...state,
      error: undefined,
    };
  });
});

export default reportingReducer;
