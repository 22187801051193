import { QueryParams } from "./../../types/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { REPORTING_ACTIONS } from "../actions/reportingActions";
import {
  fetchQueriesAnswersDailyCount,
  fetchQueriesAnswersFrequency,
  fetchQueriesAnswersSchoolCount,
} from "../../services/api/queries.api";
import { QueryFrequencyAnswers } from "../../types/queries";
import dayjs from "dayjs";

export const getNoAnswersStudentsTable = createAsyncThunk<
  any,
  QueryParams | undefined
>(REPORTING_ACTIONS.GET_NO_ANSWERS_STUDENTS_TABLE, async (params, thunkAPI) => {
  try {
    // console.log("Effect, ",  params);
    const { data: responseData } =
      params === undefined
        ? await fetchQueriesAnswersFrequency()
        : await fetchQueriesAnswersFrequency(params);

    return responseData.data;
  } catch (error) {
    console.log("Countries effects error: ", error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getNoAnswersStudents24h = createAsyncThunk<QueryFrequencyAnswers>(
  REPORTING_ACTIONS.GET_NO_ANSWERS_STUDENTS_24H,
  async (params, thunkAPI) => {
    try {
      const params: QueryParams = {
        start_date: dayjs().add(-1, "day").format("DD/MM/YYYY").toString(),
        end_date: dayjs().format("DD/MM/YYYY").toString(),
      };
      const { data: responseData } = await fetchQueriesAnswersFrequency(params);

      return responseData.data;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBarChartQuestionnaires = createAsyncThunk<
  any,
  QueryParams | undefined
>(REPORTING_ACTIONS.GET_BAR_CHART_QUESTIONNAIRES, async (params, thunkAPI) => {
  try {
    const { data: responseData } =
      params === undefined
        ? await fetchQueriesAnswersSchoolCount({
            start_date: dayjs().add(-1, "day").format("DD/MM/YYYY").toString(),
            end_date: dayjs().format("DD/MM/YYYY").toString(),
            country: "Greece",
          })
        : await fetchQueriesAnswersSchoolCount(params);

    return responseData.data;
  } catch (error) {
    console.log("Countries effects error: ", error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getLineChartQuestionnaires = createAsyncThunk<
  any,
  QueryParams | undefined
>(REPORTING_ACTIONS.GET_LINE_CHART, async (params, thunkAPI) => {
  try {
    const { data: responseData } =
      params === undefined
        ? await fetchQueriesAnswersDailyCount({
            start_date: dayjs().add(-30, "day").format("DD/MM/YYYY").toString(),
            end_date: dayjs().format("DD/MM/YYYY").toString(),
          })
        : await fetchQueriesAnswersDailyCount(params);

    return responseData.data;
  } catch (error) {
    console.log("Countries effects error: ", error);
    return thunkAPI.rejectWithValue(error);
  }
});
