import { elementsS1 } from "./partA/section1";
import { elementsS2 } from "./partB/section2";
import { elementsS3 } from "./partB/section3";
import { elementsS4 } from "./partB/section4";
import { elementsS5 } from "./partB/section5";
import { elementsS6 } from "./partB/section6";
import { elementsS7 } from "./partC/section7.";
import { elementsS8 } from "./partC/section8";

export const questionnaire3 = {
  id: "3",
  preview: "/q3preview.png",
  checkErrorsMode: "onValueChanged",
  answersNum: 1,
  active: {
    Greece : {
      from : "01/04/2024",
      to: "01/01/2024"
    }
  },
  title: {
    default: "Final questionnaire",
    gr: "Τελικό ερωτηματολόγιο",
    fr: "Questionnaire final",
    de: "",
    ka: "საბოლოო კითხვარი",
    fi: "Viimeinen kyselylomake",
  },
  showQuestionNumbers: "on",
  description: {
    default: "Final questionnaire for total cohort",
    gr: "Τελικό ερωτηματολόγιο για τη συνολική κοόρτη",
    fr: "Questionnaire final",
    de: "",
    ka: "საბოლოო კითხვარი კოჰორტის კვლევისთვის",
    fi: "Viimeinen kyselylomake koko kohortille",
  },
  pages: [
    {
      name: "section1",
      description: {
        default: "General health",
        gr: "Γενική υγεία",
        fr: "Santé générale",
        de: "",
        ka: "ზოგადი მონაცემები ჯანმრთელობის შესახებ",
        fi: "Yleinen terveys ",
      },
      elements: elementsS1,
    },
    {
      name: "section2",
      description: {
        default: "Respiratory and atopy history - Asthma",
        gr: "Αναπνευστικό και ατοπικό ιστορικό - Άσθμα",
        fr: "Antécédents respiratoires et atopiques - ",
        de: "",
        ka: "რესპირაციული და ატოპიური დაავადებების ისტორია - ასთმა",
        fi: "Hengitysteiden ja atopian historia - Astma",
      },
      elements: elementsS2,
    },
    {
      name: "section3",
      description: {
        default: "Respiratory and atopy history - Rhinitis",
        gr: "Αναπνευστικό και ατοπικό ιστορικό - Ρινίτιδα",
        fr: "Antécédents respiratoires et atopiques - ",
        de: "",
        ka: "რესპირაციული და ატოპიური დაავადებების ისტორია - რინიტი",
        fi: "Hengitysteiden ja atopian historia - Nuha",
      },
      elements: elementsS3,
    },
    {
      name: "section4",
      description: {
        default: "Respiratory and atopy history - Eczema",
        gr: "Αναπνευστικό και ατοπικό ιστορικό - Έκζεμα",
        fr: "Antécédents respiratoires et atopiques - ",
        de: "",
        ka: "რესპირაციული და ატოპიური დაავადებების ისტორია - ეგზემა",
        fi: "Hengitysteiden ja atopian historia - Ihottuma",
      },
      elements: elementsS4,
    },
    {
      name: "section5",
      description: {
        default: "Respiratory and atopy history - Food Allergies",
        gr: "Αναπνευστικό και ατοπικό ιστορικό - Τροφική αλλεργία",
        fr: "Antécédents respiratoires et atopiques - ",
        de: "",
        ka: "რესპირაციული და ატოპიური დაავადებების ისტორია - კვებითი ალერგია",
        fi: "Hengitysteiden ja atopian historia - Ruoka-allergia",
      },
      elements: elementsS5,
    },
    {
      name: "section6",
      title: {
        default: "The following questions are about the past three months:",
        gr: "Οι παρακάτω ερωτήσεις αφορούν τους τελευταίους 3 μήνες:",
        fr: "Au cours des trois derniers mois:",
        de: "",
        ka: "ბოლო სამი თვის განმავლობაში:",
        fi: "Viimeisten kolmen kuukauden aikana:",
      },
      elements: elementsS6,
    },
    {
      visibleIf: "{q3s6q15} = 'yes'",
      name: "section2",
      title: {
        default: "Exposures ",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s room/home. In this section we ask a number of questions on your child’s home. For each question, please provide answers for the home in which your child lives at present, and for the home in which your child lived during the first year of life. (In case you have moved, please choose the home in which your child spent most of his or her time during the first year of life). Please make sure that you tick both columns! ",
        gr: "Το δωμάτιο/σπίτι του παιδιού. Σε αυτήν την ενότητα θέλουμε να απαντήσετε μια σειρά ερωτήσεων σχετικά με το σπίτι του παιδιού σας. Για κάθε ερώτηση, παρακαλώ να δώσετε απαντήσεις για το σπίτι στο οποίο διαμένει σήμερα και για το σπίτι στο οποίο διέμενε κατά τη διάρκεια του πρώτου χρόνου της ζωής του. (Σε περίπτωση που έχετε μετακομίσει, παρακαλώ επιλέξτε το σπίτι στο οποίο το παιδί σας πέρασε τον περισσότερο χρόνο κατά τον πρώτο χρόνο της ζωής του). Παρακαλούμε σιγουρευτείτε ότι έχετε σημειώσει τις απαντήσεις σας και στις δύο στήλες!",
        fr: "Chambre/domicile de l’enfant. Dans cette section, nous posons un certain nombre de questions sur le foyer de votre enfant. Pour chaque question, veuillez répondre pour le foyer dans lequel votre enfant vit actuellement et pour le foyer dans lequel il a vécu au cours de sa première année de vie. (Si vous avez déménagé, veuillez choisir le domicile dans lequel votre enfant a passé la majeure partie de son temps au cours de sa première année de vie). Veillez à cocher les deux colonnes!",
        de: "",
        ka: "ბავშვის ოთახი/სახლი. ამ ნაწილში წარმოდგენილი კითხვები  თქვენი შვილის  საცხოვრებელი სახლის შესახებ. გთხოვთ, გვიპასუხოთ კითხვებზე, რომლებიც ეხება იმ საცხოვრებელ სახლს, სადაც თქვენი შვილი ამჟამად ცხოვრობს და ასევე, იცხოვრა დაბადებიდან პირველი წლის განმავლობაში (იმ შემთხვევაში, თუ თქვენ შეიცვალეთ საცხოვრებელი, გთხოვთ პასუხებისთვის აირჩიოთ ის სახლი, სადაც ბავშვმა გაატარა მეტი დრო დაბადებიდან  პირველ წელს). გთხოვთ,მიაქციოთყურადღება, რომ მონიშნული იყოს ორივე სვეტი!",
        fi: "Tässä osiossa kysymme useita kysymyksiä lapsesi kodista. Vastatkaa kuhunkin kysymykseen siitä kodista, jossa lapsenne asuu tällä hetkellä, ja siitä kodista, jossa lapsenne asui ensimmäisen elinvuotensa aikana. (Jos olette muuttaneet, valitkaa se koti, jossa lapsenne vietti suurimman osan ajastaan ensimmäisen elinvuotensa aikana). Varmistaa, että rastitatte molempiin sarakkeisiin!",
      },
      elements: elementsS7,
    },
    {
      visibleIf: "{q3s6q15} = 'yes'",
      name: "section3",
      title: {
        default: "Exposures",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s home building",
        gr: "Το κτίριο του σπιτιού που μένει το παιδί σας",
        fr: "Construction de la maison de l’enfant",
        de: "",
        ka: "ბავშვის საცხოვრებელი შენობა",
        fi: "Lapsen kotirakennus",
      },
      elements: elementsS8,
    },
  ],
};
