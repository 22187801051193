import {
  Box,
  Container,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import CustomTable from "../../../components/Table/CustomTable";
import { getRole } from "../../../services/api/utils.api";
import { TableBar } from "../../../components/styled/AppBar";
import { StyledFormControl } from "../../../components/styled/StyledFormSelect";
import { theme } from "../../../consts/theme";
import { Column } from "../../../types/table";
import { UserSchema } from "../../../types/user";
import { useEffect, useState } from "react";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { selectQuestionnaires } from "../../../store/selectors/questionnaireSelector";
import { getParentAnswers } from "../../../services/api/pquestionnaires.api";
import { ParentAnsweredQuestionnaires } from "../../../types/questionnaire";
import Loading from "../../../components/Loading";
import { getQuestionnaires } from "../../../store/effects/questionnaireEffects";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import StudentExportForm from "../../../components/Forms/StudentExportForm";
import { StyledButton } from "../../../components/styled/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ParentExportForm from "../../../components/Forms/ParentExportForm";

const ParentsAnalyticsPage = () => {
  const role = getRole();
  const PARENT_TEXT = "Parent Questionnaires";
  const questionnaires = useAppSelector(selectQuestionnaires)
    .map((questionnaire) => ({
      id: questionnaire._id,
      name: questionnaire.title.default,
    }))
    .sort((a, b) => a.id.localeCompare(b.id));
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("1");
  // const schools = useAppSelector(selectSchools);
  const [data, setData] = useState<ParentAnsweredQuestionnaires[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppThunkDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  console.log("Q:", questionnaires);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getQuestionnaires());
    getParentAnswers()
      .then((res) => {
        const uniqueDataMap = new Map();
        res.data.data.forEach((item) => {
          const combination = `${item.questionnaire_id}-${item.student}`;
          if (!uniqueDataMap.has(item.student)) {
            uniqueDataMap.set(combination, item);
          }
        });

        const uniqueData = Array.from(uniqueDataMap.values());
        setData(uniqueData);
        setIsLoading(false);
      })
      .catch((e) => {
        handleApiError(e, setError);
        setIsLoading(false);
      });
  }, []);

  const changeQuestionnaire = (e: SelectChangeEvent) => {
    setSelectedQuestionnaire(e.target.value);
  };

  const ProcessRows = (
    data: ParentAnsweredQuestionnaires[]
  ): UserSchema<ParentAnsweredQuestionnaires>[] => {
    const filteredData = data.filter(
      (item) => item.questionnaire_id === selectedQuestionnaire
    );

    return filteredData.map((row) => {
      return { ...row };
    });
  };

  const columns: Column<
    ParentAnsweredQuestionnaires,
    keyof ParentAnsweredQuestionnaires
  >[] = [
    // { name: "ID", key: "_id" },
    { name: "USERNAME", key: "student" },
    { name: "UNIQUE ID", key: "offline_code" },
    { name: "SCHOOL", key: "school" },
  ];

  const ParentsTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "10px", md: "20px" },
              marginRight: "10px",
            }}
          >
            <h2>{`${PARENT_TEXT}`}</h2>
            {/* <Typography variant='h2' noWrap>{`${PARENT_TEXT}`}</Typography> */}
          </Box>
          <ThemeProvider theme={theme}>
            <StyledFormControl fullWidth>
              <InputLabel id='select-qs'>Questionnaires</InputLabel>
              <Select
                labelId='pqs'
                id='pqs'
                value={selectedQuestionnaire}
                label='Questionnaires'
                onChange={(e) => changeQuestionnaire(e)}
                size='small'
                sx={{
                  width: "40%",
                  // backgroundColor: "white",
                  // borderRadius: "50px",
                  // "& fieldset": {
                  //   borderRadius: "50px",
                  // },
                }}
              >
                {questionnaires.map((questionnaire, index) => (
                  <MenuItem key={index} value={questionnaire.id}>
                    {questionnaire.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: { xs: "10px", sm: "20px", md: "30px" },
                alignItems: "center",
              }}
            >
              <PermissionControl
                permissionPath={
                  permissions.pages.questionnaires.export
                    ? permissions.pages.questionnaires.export
                    : []
                }
                role={role}
                route={false}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Tooltip
                    title={`Download Parents' Questionnaires`}
                    placement='top'
                  >
                    <StyledButton
                      sx={{ marginRight: "0px" }}
                      variant='contained'
                      onClick={handleOpen}
                    >
                      <DownloadIcon />
                    </StyledButton>
                  </Tooltip>
                </Box>
              </PermissionControl>
            </Box>
          </ThemeProvider>
        </Toolbar>
      </TableBar>
    );
  };

  // if (isLoading) return <Loading />;

  return (
    <Container max-width='xl'>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <div>
          <ParentExportForm modal={handleClose} />
        </div>
      </Modal>
      <PermissionControl
        permissionPath={permissions.pages.analytics.access}
        role={role}
      >
        {!isLoading ? (
          <CustomTable
            toolbar={ParentsTableToolbar}
            rows={ProcessRows(data)}
            columns={columns}
          />
        ) : (
          <Loading />
        )}
      </PermissionControl>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default ParentsAnalyticsPage;
