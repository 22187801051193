"use client";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import SurveyComponent from "./SurveyComponent";
import { updateParentAnswers } from "../../services/api/pquestionnaires.api";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import {
  selectAllowAnswers,
  selectDrafts,
  selectQuestionnaires,
} from "../../store/selectors/questionnaireSelector";
import {
  Draft,
  ParentAnswers,
  ParentQuestionnaireAnswers,
} from "../../types/questionnaire";
import { getDrafts } from "../../store/effects/questionnaireEffects";
import { setAllowAnswers } from "../../store/actions/questionnaireActions";
import Loading from "../Loading";
import { postHealthConditionTags } from "../../services/api/users.api";
import { getHealthTags } from "../../utils/tags-functions";
import { handleApiError } from "../../utils/handleApiError";
import AlertDialog from "../Alert";

const SurveyHandler = ({ id, draft_id }: { id: string; draft_id?: string }) => {
  const drafts = useAppSelector(selectDrafts);
  const allow_answers = useAppSelector(selectAllowAnswers);
  const questionnaire = useAppSelector(selectQuestionnaires).find(
    (q) => q._id === id
  );
  const draft = drafts.find((q) => q._id === draft_id);
  const [answers, setAnswers] = useState<ParentAnswers>();
  const dispatch = useAppThunkDispatch();
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();

  // console.log("Draft:", draft);
  // console.log("Data:", answers);
  // console.log("Questionnaire: ", questionnaire);

  const saveData = async (
    data: ParentQuestionnaireAnswers,
    draft: boolean
  ): Promise<boolean> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      data["_id"] = draft_id;
      const qId = data["id"];
      const isBaseLine = qId === "1v2";
      console.log(data);
      console.log("Tags", getHealthTags(data));
      try {
        const res = await updateParentAnswers(data);
        if (res.status === 200) {
          if (!draft) {
            dispatch(setAllowAnswers({ ...allow_answers, [qId]: false }));
            isBaseLine &&
              postHealthConditionTags(data)
                .then((res) => {
                  if (res.status === 200) {
                    setAlert(
                      "A new questionnaire is now available for you to complete."
                    );
                  }
                })
                .catch((e) => {
                  handleApiError(
                    e,
                    setError,
                    " !!ERROR OCCURED!! Please contact your local administrator to have them activate the new case questionnaire, which will then be available for you to complete."
                  );
                });
          }
          await dispatch(getDrafts());
          resolve(true);
        } else {
          setAlert("Your data is not saved");
          resolve(false);
        }
      } catch (e) {
        console.error("An error occurred: ", e);
        handleApiError(e, setError);
        resolve(false);
      }
    });
  };

  useEffect(() => {
    console.log(draft);
    if (draft) {
      console.log(draft);
      const {
        is_draft,
        last_updated,
        questionnaire_id,
        student,
        _id,
        ...draft_answers
      }: ParentAnswers = draft;
      // console.log("Answers: ", draft_answers);
      setAnswers(draft_answers);
    }
  }, [draft]);

  return (
    <Container maxWidth='xl'>
      {questionnaire && answers ? (
        <SurveyComponent
          questionnaire={questionnaire}
          answers={answers}
          saveData={saveData}
        />
      ) : (
        <Loading />
      )}
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                error && setError(undefined);
                alert && setAlert(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default SurveyHandler;
