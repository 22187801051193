import axios, { AxiosError, AxiosResponse } from "axios";
import { getToken } from "./utils.api";
import { ApiResponse } from "../../types/api";
import { useLogout } from "../../hooks/auth/useLogout";

export const Axios = (
  url: string,
  withoutHeaders = false,
  exported_zip = false
) => {
  // console.log("axios", getToken());
  // console.log("GetConfig('KEYCLOACK_URI')", GetConfig("KEYCLOACK_URI"));

  const instance = axios.create({
    baseURL: url,
    timeout: 300000,
    timeoutErrorMessage: "Time out!",
    headers: {
      Authorization: withoutHeaders || `Bearer ${getToken()}`,
      "Content-Type": exported_zip ? "application/zip" : "application/json",
    },
    // responseType: exported_file ? "arraybuffer" : "json",
    responseType: exported_zip ? "blob" : "json",
  });

  // Request interceptor
  instance.interceptors.request.use(
    (req) => {
      return req;
    },
    (e) => {
      // console.log("Error from request interceptor: ", e.response);
      return handleError(e);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (e) => {
      // console.log("Error intercepted from response: ", e.response);
      return handleError(e);
    }
  );

  return instance;
};

const handleError = (
  error: AxiosError<ApiResponse<any>>
): Promise<AxiosError> => {
  const { logout } = useLogout();
  if (error.response) {
    if (error.response.status === 403 || error.response.status === 401) {
      logout();
      window.location.pathname !== "/login" &&
        window.location.replace("/login");
      return Promise.reject(error);
    }
    // console.log("Error Axios: ", error.message);
    //   let errorMsg = "";
    //   if (error.response.data.errors[1]) {
    //     errorMsg = error.response.data.errors[1];
    //   } else {
    //     errorMsg = error.response.data.errors[0];
    //   }
    //   console.log("Error: ", errorMsg);
    //   alert(errorMsg);
  }

  return Promise.reject(error); //Promise.resolve(error);
};
