import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s2q1 = {
  type: "radiogroup",
  name: "q3s2q1",
  title: {
    default:
      "Has your child had wheezing or whistling in the chest in the past 10 months?",
    gr: "Είχε το παιδί σας «βράσιμο»  ή σφύριγμα στο στήθος, τους τελευταίους 10 μήνες; ",
    fr: "Votre enfant a-t-il/elle eu une respiration sifflante ou des sifflements au cours des 10 derniers mois ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ხიხინი ან სტვენა გულმკერდში ბოლო 10 თვის განმავლობაში? ",
    fi: "Onko lapsellanne ollut hengityksen vinkumista tai rintakehä viheltelyä viimeisen 10 kk aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s2q2 = {
  type: "radiogroup",
  name: "q3s2q2",
  title: {
    default:
      "How many attacks of wheezing has your child had in the past 10 month?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα στο στήθος είχε το παιδί σας τους τελευταίους 10 μήνες;",
    fr: "Combien de crises de respiration sifflante votre enfant a-t-il/elle eues au cours des 10 derniers mois ?",
    de: "",
    ka: "ხიხინის რამდენი შეტევა ჰქონდა თქვენს ბავშვს ბოლო 10 თვის განმავლობაში?",
    fi: "Kuinka monta hengityksen vinkumiskohtausta lapsellanne on ollut viimeisen 10 kk aikana?",
  },
  isRequired: isRequired,
  colCount: 4, 
  choices: ["0", "1-3", "4-12", ">12"],
};

const s2q3 = {
  type: "radiogroup",
  name: "q3s2q3",
  title: {
    default:
      "In the past 10 months, how often, on average, has your child’s sleep been disturbed due to wheezing?",
    gr: "Τους τελευταίους 10 μήνες, πόσο συχνά κατά μέσο όρο, ξύπνησε το παιδί σας από «βράσιμο» ή σφύριγμα στο στήθος;",
    fr: "Au cours des 10 derniers mois, à quelle fréquence, en moyenne, le sommeil de votre enfant a-t-il été perturbé en raison d’une respiration sifflante?",
    de: "",
    ka: "ბოლო 10 თვის განმავლობაში საშუალოდ რა სიხშირით ჰქონდა თქვენს ბავშვს ხიხინით გამოწვეული ძილის დარღვევა?",
    fi: "Kuinka usein keskimäärin lapsenne uni on viimeisen 10 kk aikana häiriintynyt hengityksen vinkumisen vuoksi?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never woken with wheezing",
        gr: "Ποτέ",
        fr: "Jamais réveillé avec une respiration sifflante ",
        de: "",
        ka: "ხიხინს არასოდეს გაუღვიძებია ",
        fi: "Ei ole koskaan herännyt hengityksen vinkumiseen",
      },
    },
    {
      value: "less_than_one_pw",
      text: {
        default: "Less than one night per week",
        gr: "Λιγότερο από μία νύχτα την εβδομάδα",
        fr: "Moins d’une nuit par semaine",
        de: "",
        ka: "უფრო იშვიათად, ვიდრე კვირაში ერთხელ",
        fi: "Vähemmän kuin kerran viikossa",
      },
    },
    {
      value: "more_than_one_pw",
      text: {
        default: "One or more nights per week",
        gr: "Μια ή περισσότερες νύχτες την εβδομάδα",
        fr: "Une ou plusieurs nuits par semaine",
        de: "",
        ka: "კვირაში ერთ ღამეს ან უფრო ხშირად",
        fi: "Yhden tai useamman kerran viikossa",
      },
    },
  ],
};

const s2q4 = {
  type: "radiogroup",
  name: "q3s2q4",
  title: {
    default:
      "In the past 10 months, has wheezing ever been severe enough to limit your child’s speech to only one or two words at a time between breaths?",
    gr: "Τους τελευταίους 10 μήνες, ήταν ποτέ τόσο σοβαρό το «βράσιμο» ή το σφύριγμα ώστε να δυσκολεύεται να μιλήσει;",
    fr: "Au cours des 10 derniers mois, la respiration sifflante a-t-elle déjà été suffisamment grave pour limiter l’élocution de votre enfant à un ou deux mots à la fois entre deux respirations ? ",
    de: "",
    ka: "ბოლო 10 თვის განმავლობაში ჰქონია თუ არა თქვენს ბავშვს ოდესმე იმდენად ძლიერი ხიხინი, რომ ლაპარაკში ხელი შეშლოდა და ჩასუთქვებს შორის მხოლოდ 1-2 სიტყვის თქმა შეძლებოდა?",
    fi: "Onko hengityksen vinkuminen ollut viimeisen 10 kk aikana niin voimakasta, että lapsenne puhe on rajoittunut vain yhteen tai kahteen sanaan kerrallaan hengitysten välillä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

export const elementsS2 = [s2q1, s2q2, s2q3, s2q4];
