import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/Login";
import DashboardPage from "./pages/dashboard/main/Dashboard";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/dashboard/users/UserPage";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import CreateUserPage from "./pages/dashboard/users/CreateUserPage";
import EditUserPage from "./pages/dashboard/users/EditUserPage";
import PlayerPage from "./pages/dashboard/players/PlayerPage";
import CreatePlayerPage from "./pages/dashboard/players/CreatePlayerPage";
import EditPlayerPage from "./pages/dashboard/players/EditPlayerPage";
import UploadPlayersPage from "./pages/dashboard/players/UploadPlayersPage";
import SchoolsPage from "./pages/dashboard/schools/SchoolPage";
import CreateSchoolPage from "./pages/dashboard/schools/CreateSchoolPage";
import EditSchoolPage from "./pages/dashboard/schools/EditSchoolPage";
import AnalyticsPage from "./pages/dashboard/analytics/AnalyticsPage";
import QuestionnairesPage from "./pages/dashboard/questionnaires/QuestionnairesPage";
import CreateQuestionnairePage from "./pages/dashboard/questionnaires/CreateQuestionnairePage";
import QuestionnairesDraftPage from "./pages/dashboard/questionnaires/EditQuestionnaire";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route element={<DashboardLayout />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/dashboard/users' element={<UserPage />} />
          <Route
            path='/dashboard/users/createUser'
            element={<CreateUserPage />}
          />
          <Route path='/dashboard/users/:id' element={<EditUserPage />} />
          <Route path='/dashboard/players' element={<PlayerPage />} />
          <Route
            path='/dashboard/players/createPlayer'
            element={<CreatePlayerPage />}
          />
          <Route path='/dashboard/players/:id' element={<EditPlayerPage />} />
          <Route
            path='/dashboard/players/upload'
            element={<UploadPlayersPage />}
          />
          <Route path='/dashboard/schools' element={<SchoolsPage />} />
          <Route
            path='/dashboard/schools/createSchool'
            element={<CreateSchoolPage />}
          />
          <Route path='/dashboard/schools/:id' element={<EditSchoolPage />} />
          <Route path='/dashboard/analytics' element={<AnalyticsPage />} />
          <Route
            path='/dashboard/questionnaires'
            element={<QuestionnairesPage />}
          />
          <Route
            path='/dashboard/questionnaires/:id'
            element={<CreateQuestionnairePage />}
          />
          <Route
            path='/dashboard/questionnaires/:id/:draft_id'
            element={<QuestionnairesDraftPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
