import { debounce } from "lodash";
import { Model } from "survey-core";
import { ParentQuestionnaireAnswers, TitleLoc } from "../types/questionnaire";

export const registerSurveyListeners = (
  survey: Model,
  questionnaire_id: string,
  saveDataCB: (
    data: ParentQuestionnaireAnswers,
    draft: boolean
  ) => Promise<boolean>,
  showBackButtonCB: (state: boolean) => void,
  setCompletedCB: (state: boolean) => void,
  setFlagCB: (state: boolean) => void,
  lan: string
) => {
  const titleLoc: TitleLoc = {
    en: "Save Draft",
    // gr: "Αποθήκευση Προσχεδίου",
    // fr: "Enregistrer le brouillon",
    // ka: "მონახაზის შენახვა",
    // fi: "Tallenna luonnos"
  };

  survey.addNavigationItem({
    id: "sv-nav-save-draft",
    title: titleLoc[lan as keyof TitleLoc],
    action: () => {
      console.log(survey.data);
      const data = survey.data;
      data["questionnaire_id"] = questionnaire_id;
      data["is_draft"] = true;
      saveDataCB(data, true).then((flag) => {
        // console.log("SAVED");
        setFlagCB(flag);
      });
    },
    css: "nav-button",
    innerCss: "sd-btn nav-input",
  });

  survey.onValueChanged.add(() => {
    // console.log("LOADER");
    setFlagCB(false);
  });

  const debounceFunction = debounce((survey, options) => {
    // console.log("Devounce");

    const data = survey.data;
    data["questionnaire_id"] = questionnaire_id;
    data["is_draft"] = true;
    // console.log("SAVED");
    saveDataCB(data, true).then((flag) => {
      // console.log("SAVED");
      setFlagCB(flag);
    });
    // setFlagCB(true);
  }, 5000);

  survey.onValueChanged.add(debounceFunction);

  survey.onComplete.add((sender: any, options: any) => {
    setFlagCB(false);
    const data = sender.data;
    data["id"] = questionnaire_id;
    data["is_draft"] = false;
    console.log("Completed:", data);
    setCompletedCB(true);
    setTimeout(() => {
      saveDataCB(data, false).then((flag) => {
        // console.log("SAVED");
        setFlagCB(flag);
        showBackButtonCB(true);
      });
      // setFlagCB(true);
    }, 5000);
    // survey.onValueChanged.remove(debounceFunction); //not working
  });

  //   survey.addNavigationItem({
  //     id: "sv-nav-clear-page",
  //     title: "Clear Page",
  //     action: () => {
  //         survey.currentPage.questions.forEach((question) => {
  //             question.value = undefined;
  //         });
  //     },
  //     css: "nav-button",
  //     innerCss: "sd-btn nav-input"
  // });
};
