import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s1q1 = {
  type: "radiogroup",
  name: "q3s1q1",
  title: {
    default: "Does your child have any health problems?",
    gr: "Έχει κάποιο πρόβλημα υγείας το παιδί σας;",
    fr: "Votre enfant a-t-il/elle des problèmes de santé ?",
    de: "",
    ka: "აქვს თუ არა თქვენს შვილს ჯანმრთელობასთან დაკავშირებული რაიმე პრობლემა?",
    fi: "Onko lapsellasi terveysongelmia?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q2 = {
  type: "tagbox",
  name: "q3s1q2",
  title: {
    default: "Which ones?",
    gr: "Εάν Ναι...",
    fr: "Lesquels parmi les suivants?",
    de: "",
    ka: "მონიშნეთ შესაბამისი პარამეტრები ქვემოთ",
    fi: "Alla tarkentavia vaihtoehtoja",
  },
  visibleIf: "{q3s1q1} == 'yes' ",
  isRequired: isRequired,
  choices: [
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic rhinitis/rhinoconjunctivitis/hay fever",
        gr: "Αλλεργική ρινίτιδα/ρινοεπιπεφυκίτιδα/αλλεργικό πυρετό",
        fr: "Rhinite allergique/rhino-conjonctivite/rhume des foins",
        de: "",
        ka: "ალერგიული რინიტი/რინოკონიუქტივიტი/თივის ცხელება",
        fi: "Allerginen nuha/silmän sidekalvontulehdus/heinänuha",
      },
    },
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
    },
    {
      value: "atopic_dermatitis",
      text: {
        default: "Atopic dermatitis/Eczema",
        gr: "Ατοπική Δερματίτιδα/Έκζεμα",
        fr: "Dermatite atopique",
        de: "",
        ka: "ატოპიური დერმატიტი",
        fi: "Atooppinen ihottuma",
      },
    },
    {
      value: "diabetes_melitus",
      text: {
        default: "Diabetes mellitus",
        gr: "Σακχαρώδης διαβήτης",
        fr: "Diabète",
        de: "",
        ka: "შაქრიანი დიაბეტი",
        fi: "Diabetes",
      },
    },
    {
      value: "cardiovascular_diseases",
      text: {
        default: "Cardiovascular diseases",
        gr: "Καρδιαγγειακές παθήσεις",
        fr: "Maladies cardiovasculaires",
        de: "",
        ka: "გულსისხლძარღვთა დაავადებები",
        fi: "Sydän- ja verisuonitaudit",
      },
    },
    {
      value: "chronic_lung_diseases",
      text: {
        default: "Chronic lung diseases",
        gr: "Χρόνια πνευμονολογικά νοσήματα",
        fr: "Maladie pulmonaire chronique",
        de: "",
        ka: "ფილტვის ქრონიკული დაავადებები",
        fi: "Krooniset keuhkosairaudet",
      },
    },
    {
      value: "thyroid_disease",
      text: {
        default: "Thyroid disease",
        gr: "Θυρεοειδική νόσος",
        fr: "Maladie thyroïdienne",
        de: "",
        ka: "ფარისებრი ჯირკვლის დაავადებები",
        fi: "Kilpirauhasen sairaus",
      },
    },
    {
      value: "nephrological_diseases",
      text: {
        default: "Kindney diseases",
        gr: "Παθήσεις νεφρών",
        fr: "Problèmes néphrologiques",
        de: "",
        ka: "თირკმლის დაავადებები",
        fi: "Munuaisongelmia",
      },
    },
    {
      value: "neurodevelopment_problems",
      text: {
        default: "Neurodevelopment problems",
        gr: "Νευροαναπτυξιακά προβλήματα",
        fr: "Troubles du neurodéveloppement",
        de: "",
        ka: "ნეიროგანვითარების პრობლემები",
        fi: "Neurologiset kehitysongelmat",
      },
    },
  ],
  description: selectMultiple,
};

const s1q3 = {
  type: "radiogroup",
  name: "q3s1q3",
  title: {
    default: "Does your child take any medication for any reason? ",
    gr: "Λαμβάνει το παιδί σας φαρμακευτική αγωγή για οποιοδήποτε λόγο;",
    fr: "Votre enfant prend-il/elle des médicaments pour quelque raison que ce soit ?",
    de: "",
    ka: "იღებს თუ არა  თქვენი შვილი რომელიმე  მედიკამეტს?",
    fi: "Käyttääkö lapsenne lääkkeitä jostain syystä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q4 = {
  type: "tagbox",
  name: "q3s1q4",
  isRequired: isRequired,
  showOtherItem: true,
  title: {
    default: "Which ones?",
    gr: "Εάν Ναι...",
    fr: "Lesquels parmi les suivants ?",
    de: "",
    ka: "მონიშნეთ შესაბამისი პარამეტრები ქვემოთ",
    fi: "Alla tarkentavia vaihtoehtoja",
  },
  visibleIf: "{q3s1q3} == 'yes' ",
  choices: [
    {
      value: "inhalers",
      text: {
        default: "Inhalers",
        gr: "Εισπνεόμενα",
        fr: "Inhalateurs pour la respiration",
        de: "",
        ka: "ინჰალატორები",
        fi: "Inhalaattorit",
      },
    },
    {
      value: "insulin",
      text: {
        default: "Insulin",
        gr: "Ινσουλίνη",
        fr: "Insuline",
        de: "",
        ka: "ინსულინი",
        fi: "Insuliini",
      },
    },
    {
      value: "cardiovascular_drugs",
      text: {
        default: "Cardiovascular drugs",
        gr: "Καρδιολογικά φάρμακα",
        fr: "Médicaments pour pathologies cardiaques",
        de: "",
        ka: "კარდიოვასკულარული მედიკამენტები",
        fi: "Sydän- ja verisuonilääkkeet",
      },
    },
    {
      value: "antihistamines_for_rhinitis",
      text: {
        default: "Antihistamines for rhinitis",
        gr: "Αντιισταμινικά για ρινίτιδα",
        fr: "Antihistaminiques pour la rhinite",
        de: "",
        ka: "ანტიჰისტამინები ალერგიული რინიტის სამკურნალოდ",
        fi: "Nuhan hoitoon käytettävät antihistamiinit",
      },
    },
    {
      value: "nasal_sprays",
      text: {
        default: "Nasal sprays",
        gr: "Ρινικά σπρέϊ",
        fr: "Sprays nasaux",
        de: "",
        ka: "ნაზალური სპრეი",
        fi: "Nenäsumutteet",
      },
    },
  ],
  description: selectMultiple,
};

const s1q5 = {
  type: "radiogroup",
  name: "q3s1q5",
  colCount: 4,
  title: {
    default:
      "During a normal week, how many hours does your child watch television/play video games a day?",
    gr: "Κατά τη διάρκεια μιας συνηθισμένης εβδομάδας, πόσες ώρες την ημέρα το παιδί σας παρακολουθεί τηλεόραση/παίζει ηλεκτρονικά παιχνίδια;",
    fr: "Au cours d’une semaine normale, combien d’heures votre enfant regarde-t-il/elle la télévision ou joue-t-il à des jeux vidéo par jour?",
    de: "",
    ka: "ჩვეულებრივი კვირის განმავლობაში რამდენ საათს უთმობს თქვენი შვილი ტელევიზორის ყურებას/ვიდეოთამაშებს დღეში?",
    fi: "Kuinka monta tuntia lapsesi katsoo normaalin viikon aikana televisiota / pelaa videopelejä päivässä?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "<1h",
      text: {
        default: "<1h",
        gr: "<1 ώρα",
        fr: "< 1 heure",
        de: "",
        ka: "<1 საათი",
        fi: "< 1 tunti",
      },
    },
    {
      value: "1-3h",
      text: {
        default: ">1h but < than 3h",
        gr: ">1 ώρα αλλά <λιγότερο από 3 ώρες",
        fr: "> 1 heure mais < 3 heures",
        de: "",
        ka: ">1 საათი მაგრამ< ვიდრე 3 საათი",
        fi: "1-3 tuntia",
      },
    },
    {
      value: "3-5h",
      text: {
        default: ">3h but < than 5h",
        gr: ">3 ώρες αλλά < από 5 ώρες",
        fr: ">3 heures mais < 5 heures",
        de: "",
        ka: ">3 საათი მაგრამ <ვიდრე 5 საათი",
        fi: "3-5 tuntia",
      },
    },
    {
      value: ">5h",
      text: {
        default: "≥5",
        gr: "≥5 ώρες",
        fr: "≥5 heures",
        de: "",
        ka: "≥5 საათი",
        fi: "≥ 5 tuntia",
      },
    },
  ],
};

const s1q6 = {
  type: "matrix",
  name: "q3s1q6",
  isRequired: isRequired,
  isAllRowRequired: isRequired,
  title: {
    default:
      "Mark how many hours your child did physical activity each day last week? ",
    gr: "Σημειώστε πόσες ώρες το παιδί σας ασχολήθηκε με κάποια σωματική δραστηριότητα κάθε μέρα την προηγούμενη εβδομάδα;",
    fr: "Cochez le nombre d’heures pendant lesquelles votre enfant a pratiqué une activité physique chaque jour de la semaine dernière.",
    de: "",
    ka: "მონიშნეთ თუ რამდენი საათი დაუთმო თქვენმა შვილმა ფიზიკურ აქტივობას ამ ბოლო კვირის თითოეულ დღეს?",
    fi: "Merkitse, kuinka monta tuntia lapsesi harrasti liikuntaa joka päivä viime viikolla?",
  },
  description: {
    default:
      "Like playing sports, games, dance, or any other physical activity",
    gr: "Όπως αθλήματα, παιχνίδια, χορό ή οποιαδήποτε άλλη σωματική δραστηριότητα ",
    fr: "Sport, jeux, danse ou toute autre activité physique",
    de: "",
    ka: "მაგ: სპორტი, თამაშები, ცეკვა და სხვ.",
    fi: "Kuten urheilua, pelejä, tanssia tai muuta liikuntaa",
  },
  columns: [
    {
      value: "0-1h",
      text: {
        default: "0-1h",
        gr: "0-1 ώρα",
        fr: "0-1h",
        de: "",
        ka: "0-1სთ",
        fi: "0-1h",
      },
    },

    {
      value: "1-2h",
      text: {
        default: "1-2h",
        gr: "1-2 ώρα",
        fr: "1-2h",
        de: "",
        ka: "1-2სთ",
        fi: "1-2h",
      },
    },
    {
      value: "2-3h",
      text: {
        default: "2-3h",
        gr: "2-3 ώρα",
        fr: "2-3h",
        de: "",
        ka: "2-3სთ",
        fi: "2-3h",
      },
    },
    {
      value: ">3h",
      text: {
        default: ">3h",
        gr: ">3 ώρες",
        fr: ">3h",
        de: "",
        ka: ">3სთ",
        fi: ">3h",
      },
    },
  ],
  rows: [
    {
      value: "monday",
      text: {
        default: "Monday",
        gr: "Δευτέρα",
        fr: "Lundi",
        de: "",
        ka: "ორშაბათი",
        fi: "Maanantai",
      },
    },
    {
      value: "tuesday",
      text: {
        default: "Tuesday",
        gr: "Τρίτη",
        fr: "Mardi",
        de: "",
        ka: "სამშაბათი",
        fi: "Tiistai",
      },
    },
    {
      value: "wednesday",
      text: {
        default: "Wednesday",
        gr: "Τετάρτη",
        fr: "Mercredi",
        de: "",
        ka: "ოთხშაბათი",
        fi: "Keskiviikko",
      },
    },
    {
      value: "thursday",
      text: {
        default: "Thursday",
        gr: "Πέμπτη",
        fr: "Jeudi",
        de: "",
        ka: "ხუთშაბათი",
        fi: "Torstai",
      },
    },
    {
      value: "friday",
      text: {
        default: "Friday",
        gr: "Παρασκευή",
        fr: "Vendredi",
        de: "",
        ka: "პარასკევი",
        fi: "Perjantai",
      },
    },
    {
      value: "saturday",
      text: {
        default: "Saturday",
        gr: "Σάββατο",
        fr: "Samedi",
        de: "",
        ka: "შაბათი",
        fi: "Lauantai",
      },
    },
    {
      value: "sunday",
      text: {
        default: "Sunday",
        gr: "Κυριακή",
        fr: "Dimanche",
        de: "",
        ka: "კვირა",
        fi: "Sunnuntai",
      },
    },
  ],
};

const s1q7 = {
  type: "multipletext",
  name: "q3s1q7",
  title: {
    default: "How many hours does your child you usually sleep at night?",
    gr: "Πόσες ώρες κοιμάται το παιδί σας συνήθως το βράδυ;",
    fr: "Combien d’heures votre enfant dort-il/elle habituellement la nuit?",
    de: "",
    ka: "ჩვეულებისამებრ, რამდენი საათი სძინავს თქვენს შვილს ღამით?",
    fi: "Kuinka monta tuntia lapsesi yleensä nukkuu yöllä?",
  },
  isRequired: isRequired,
  items: [
    {
      name: "bedtime",
      title: {
        default: "Time child goes to bed",
        gr: "Ώρα που το παιδί ξαπλώνει στο κρεβάτι",
        fr: "Heure à laquelle l’enfant se couche",
        de: "",
        ka: "დაძინების დრო",
        fi: "Lapsi menee nukkumaan klo",
      },
      inputType: "time",
      isRequired: isRequired,
    },
    {
      name: "wakeuptime",
      title: {
        default: "Time child wakes up",
        gr: "Ώρα που το παιδί ξυπνάει",
        fr: "Heure de réveil de l’enfant",
        de: "",
        ka: "გაღვიძების დრო",
        fi: "Lapsi herää klo",
      },
      inputType: "time",
      isRequired: isRequired,
    },
  ],
};
const s1q8 = {
  type: "matrixdropdown",
  name: "q3s1q8",
  isRequired: isRequired,
  title: {
    default: "Write down the corresponding immunizations in the last 10 months?",
    gr: "Σημειώστε τους αντίστοιχους εμβολιασμούς  που έγιναν τους τελευταίους 10 μήνες;",
    fr: "Indiquez les vaccinations réalisées au cours des 10 derniers mois:",
    de: "",
    ka: "აღნიშნეთ ჩატარებული იმუნიზაცია",
    fi: "Kirjoita tiedot rokotuksista viimeisen 10 kk aikana",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    {
      name: "yes_no",
      title: {
        default: "Yes/No?",
        gr: "Ναι/Όχι?",
        fr: "Oui/Non?",
        de: "",
        ka: "კი/არა?",
        fi: "Kyllä/Ei?",
      },
      choices: [yes, no],
      colCount: 2,
      cellType: "radiogroup",
      isRequired: isRequired,
    },
    {
      name: "doses",
      title: {
        default: "Doses",
        gr: "Δόσεις",
        fr: "Combien de doses",
        de: "",
        ka: "დოზები",
        fi: "Annokset",
      },
      cellType: "text",
      inputType: "number",
      min: 0,
      isRequired: isRequired,
      visibleIf: "{row.yes_no} == 'yes'",
    },
  ],
  rows: [
    {
      value: "pneumonococcal",
      text: {
        default: "Pneumonococcal",
        gr: "Πνευμονιόκοκκος",
        fr: "Pneumonocoque",
        de: "",
        ka: "პნევმოკოკის საწინააღმდეგო ვაქცინა (მწარმოებლის სახელწოდება)",
        fi: "Pneumokokki",
      },
    },
    {
      value: "haemophilus_influenza",
      text: {
        default: "Haemophilus influenza",
        gr: "Αιμόφιλος ινφλουένζας",
        fr: "Haemophilus influenza",
        de: "",
        ka: "ჰემოფილუს ინფლუენცას საწინააღმდეგო ვაქცინა(მწარმოებლის სახელწოდება)",
        fi: "Haemophilus influenza",
      },
    },
    {
      value: "influenza_last_year",
      text: {
        default: "Influenza (last year)",
        gr: "Γρίπης (για το προηγούμενο έτος)",
        fr: "Grippe",
        de: "",
        ka: "ბოლო წლის გრიპის ვაქცინა",
        fi: "Influenssa viime vuonna",
      },
    },
    {
      value: "coronavirus",
      text: {
        default: "Coronavirus (COVID-19)",
        gr: "Kοροναϊός COVID-19)",
        fr: "SARS-CoV-2 (COVID-19)",
        de: "",
        ka: "კორონავირუსის საწინააღმდეგო ვაქცინა (COVID-19)",
        fi: "Koronavirus (COVID-19)",
      },
    },
  ],
};

export const elementsS1 = [
  s1q1,
  s1q2,
  s1q3,
  s1q4,
  s1q5,
  s1q6,
  s1q7,
  s1q8,
];
