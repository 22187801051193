import { elementsS1 } from "./partA/section1";
import { elementsS2 } from "./partB/section2";
import { elementsS3 } from "./partC/section3";
import { elementsS4 } from "./partD/section4";
import { elementsS5 } from "./partD/section5";

export const questionnaire6 = {
  id: "6",
  preview: "/q6preview.png",
  checkErrorsMode: "onValueChanged",
  tag: "case",
  active: {
    Greece : {
      from : "01/04/2024",
      to: "01/04/2024"
    }
  },
  answersNum: 1,
  title: {
    default: "Case final",
    gr: "Τελικό ερωτηματολόγιο ασθενών",
    fr: "Questionnaire suivi pour les cas",
    de: "",
    ka: "საქმეები საბოლოო",
    fi: "Viimeinen tapaus kontrolli",
  },
  showQuestionNumbers: "on",
  description: {
    default: "Case final",
    gr: "Τελικό ερωτηματολόγιο ασθενών",
    fr: "Questionnaire suivi pour les cas", 
    de: "",
    ka: "საქმეები საბოლოო",
    fi: "Viimeinen tapaus kontrolli",
  },
  pages: [
    {
      name: "section1",
      title: {
        default: "In the past three months",
        gr: "Τους τελευταίους 3 μήνες",
        fr: "Au cours des trois derniers mois",
        de: "",
        ka: "ბოლო სამი თვის მანძილზე:",
        fi: "Viimeiset kolme kuukautta",
      },
      elements: elementsS1,
    },
    {
      name: "section2",
      description: {
        default: "Asthma Control Questionnaire - Select the number of the response that best describes how you have been during the past week.",
        gr: "ΕΡΩΤΗΜΑΤΟΛΟΓΙΟ ΓΙΑ ΤΟΝ ΕΛΕΓΧΟ ΤΟΥ ΑΣΘΜΑΤΟΣ - Παρακαλώ απαντήστε τις ερωτήσεις 1-6. Σκεφθείτε πως ήσαστε την ΠΕΡΑΣΜΕΝΗ ΕΒΔΟΜΑΔΑ και επιλέξτε τον αριθμό της απάντησης σας που περιγράφει καλύτερα την κατάσταση σας.",
        fr: "Questionnaire de contrôle de l’asthme (ACQ-6)",
        de: "",
        ka: "ასთმის კონტროლის კითხვარი - გთხოვთ უპასუხოთ კითხვებს 1-6. აირჩიეთ ნომერი, რომელიც ყველაზე მეტად შეესაბამება  ბოლო კვირის განმავლობაში თქვენს მდგომარეობას",
        fi: "Astman kontrolli, kyselylomake - Vastaa kysymyksiin 1–6. Valitse vastausnumero, joka kuvaa parhaiten sitä, miten olet voinut kuluneen viikon aikana.",
      },
      elements: elementsS2,
    },
    {
      name: "section3",
      description: {
        default: "Childhood Asthma Control Test for children 4 to 11 years old",
        gr: "Τεστ για τον έλεγχο του παιδικού άσθματος για παιδιά ηλικίας 4 ως 11 ετών",
        fr: "Test de contrôle de l’asthme chez l’enfant (cACT) – 4-11 ans",
        de: "",
        ka: "ბავშვთა ასთმის კონტროლის ტესტი 4-დან 11 წლამდე ბავშვებისთვის",
        fi: "Lasten astmatesti 4-11-vuotiaille lapsille",
      },
      elements: elementsS3,
    },
    {
      visibleIf: "{q6s1q24} = 'yes'",
      name: "section2",
      title: {
        default: "Exposures ",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s room/home. In this section we ask a number of questions on your child’s home. For each question, please provide answers for the home in which your child lives at present, and for the home in which your child lived during the first year of life. (In case you have moved, please choose the home in which your child spent most of his or her time during the first year of life). Please make sure that you tick both columns! ",
        gr: "Το δωμάτιο/σπίτι του παιδιού. Σε αυτήν την ενότητα θέλουμε να απαντήσετε μια σειρά ερωτήσεων σχετικά με το σπίτι του παιδιού σας. Για κάθε ερώτηση, παρακαλώ να δώσετε απαντήσεις για το σπίτι στο οποίο διαμένει σήμερα και για το σπίτι στο οποίο διέμενε κατά τη διάρκεια του πρώτου χρόνου της ζωής του. (Σε περίπτωση που έχετε μετακομίσει, παρακαλώ επιλέξτε το σπίτι στο οποίο το παιδί σας πέρασε τον περισσότερο χρόνο κατά τον πρώτο χρόνο της ζωής του). Παρακαλούμε σιγουρευτείτε ότι έχετε σημειώσει τις απαντήσεις σας και στις δύο στήλες!",
        fr: "Chambre/domicile de l’enfant. Dans cette section, nous posons un certain nombre de questions sur le foyer de votre enfant. Pour chaque question, veuillez répondre pour le foyer dans lequel votre enfant vit actuellement et pour le foyer dans lequel il a vécu au cours de sa première année de vie. (Si vous avez déménagé, veuillez choisir le domicile dans lequel votre enfant a passé la majeure partie de son temps au cours de sa première année de vie). Veillez à cocher les deux colonnes!",
        de: "",
        ka: "ბავშვის ოთახი/სახლი. ამ ნაწილში წარმოდგენილი კითხვები  თქვენი შვილის  საცხოვრებელი სახლის შესახებ. გთხოვთ, გვიპასუხოთ კითხვებზე, რომლებიც ეხება იმ საცხოვრებელ სახლს, სადაც თქვენი შვილი ამჟამად ცხოვრობს და ასევე, იცხოვრა დაბადებიდან პირველი წლის განმავლობაში (იმ შემთხვევაში, თუ თქვენ შეიცვალეთ საცხოვრებელი, გთხოვთ პასუხებისთვის აირჩიოთ ის სახლი, სადაც ბავშვმა გაატარა მეტი დრო დაბადებიდან  პირველ წელს). გთხოვთ,მიაქციოთყურადღება, რომ მონიშნული იყოს ორივე სვეტი!",
        fi: "Tässä osiossa kysymme useita kysymyksiä lapsesi kodista. Vastatkaa kuhunkin kysymykseen siitä kodista, jossa lapsenne asuu tällä hetkellä, ja siitä kodista, jossa lapsenne asui ensimmäisen elinvuotensa aikana. (Jos olette muuttaneet, valitkaa se koti, jossa lapsenne vietti suurimman osan ajastaan ensimmäisen elinvuotensa aikana). Varmistaa, että rastitatte molempiin sarakkeisiin!",
      },
      elements: elementsS4,
    },
    {
      visibleIf: "{q6s1q24} = 'yes'",
      name: "section3",
      title: {
        default: "Exposures",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s home building",
        gr: "Το κτίριο του σπιτιού που μένει το παιδί σας",
        fr: "Construction de la maison de l’enfant",
        de: "",
        ka: "ბავშვის საცხოვრებელი შენობა",
        fi: "Lapsen kotirakennus",
      },
      elements: elementsS5,
    },
  ],
};
