import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s4q1 = {
  type: "radiogroup",
  name: "q3s4q1",
  title: {
    default:
      "Has your child had this itchy rash at any time in the past 10 months?",
    gr: "Είχε ποτέ το παιδί σας αυτό το εξάνθημα με φαγούρα τους τελευταίους 10 μήνες;",
    fr: "Votre enfant a-t-il/elle déjà eu cette éruption cutanée avec démangeaisons au cours des 10 derniers mois ? ",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ქავილით მიმდინარე გამონაყარი ბოლო 10 თვის განმავლობაში?",
    fi: "Onko lapsellanne ollut tätä kutisevaa ihottumaa jossain kohtaa viimeisen 10 kk aikana? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q2 = {
  type: "radiogroup",
  name: "q3s4q2",
  title: {
    default:
      "Has this itchy rash at any time in the past 10 months affected any of the following places: the folds of the elbows, behind the knees, in front of the ankles, under the buttocks, or around the neck, ears or eyes?",
    gr: "Εμφανίστηκε ποτέ αυτό το εξάνθημα με φαγούρα σε κάποια από τα παρακάτω σημεία: στους αγκώνες, πίσω από τα γόνατα, στους αστραγάλους, κάτω από τους γλουτούς, γύρω από το λαιμό, τα αυτιά ή τα μάτια τους τελευταίους 10 μήνες;",
    fr: "Les démangeaisons ont-elles déjà affecté l’un des endroits suivants : les plis des coudes, derrière les genoux, devant les chevilles, sous les fesses, autour du cou, des oreilles ou des yeux ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ბოლო 10 თვის განმავლობაში ქავილით მიმდინარე გამონაყარი ქვემოთ ჩამოთვლილ რომელიმე უბანში: იდაყვის სახსრის ნაკეცებში, მუხლის უკან, კოჭის წინა ზედაპირზე, დუნდულოების ქვეშ ან კისრის, ყურების ან თვალების გარშემო?",
    fi: "Onko tämä kutiava ihottuma milloinkaan vaikuttanut johonkin seuraavista paikoista: kyynärpäät, polvien takana, nilkkojen edessä, pakaroiden alla tai kaulan, korvien tai silmien ympärillä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q3 = {
  type: "radiogroup",
  name: "q3s4q3",
  title: {
    default:
      "Has this rash cleared completely at any time during the past 10 months?",
    gr: "Υπάρχει κάποιο διάστημα κατά τους τελευταίους 10 μήνες κατά το οποίο το εξάνθημα εξαφανίστηκε εντελώς;",
    fr: "Cette éruption a-t-elle complètement disparu à un moment quelconque au cours des 10 derniers mois ?",
    de: "",
    ka: "ბოლო 10 თვის განმავლობაში ალაგებულა თუ არა ეს გამონაყარი მთლიანად?",
    fi: "Onko tämä ihottuma hävinnyt kokonaan milloinkaan viimeisen 10 kk aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q4 = {
  type: "radiogroup",
  name: "q3s4q4",
  title: {
    default:
      "In the past 10 months, how often, on average, has your child been kept awake at night by this itchy rash?",
    gr: "Τους τελευταίους 10 μήνες, πόσο συχνά, κατά μέσο όρο, έμεινε ξύπνιο το παιδί σας τη νύχτα εξαιτίας της φαγούρας από το εξάνθημα",
    fr: "Au cours des 10 derniers mois, combien de fois, en moyenne, votre enfant a-t-il/elle été empêché de dormir à cause de ces démangeaisons ?",
    de: "",
    ka: "ბოლო 10 თვის განმავლობაში საშუალოდ რა სიხშირით იღვიძებდა ღამით თქვენი ბავშვი ქავილით მიმდინარე გამონაყარის გამო?",
    fi: "Kuinka usein keskimäärin viimeisen 10 kk aikana tämä kutiava ihottuma on valvottanut lastanne yöllä?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never in the past 10 months",
        gr: "Ποτέ τους τελευταίους 10 μήνες",
        fr: "Jamais au cours des 10 derniers mois",
        de: "",
        ka: "არც ერთხელ ბოლო 10 თვეში",
        fi: "Ei koskaan viimeisen 10 kk aikana",
      },
    },
    {
      value: "less_than_one_pw",
      text: {
        default: "Less than one night per week",
        gr: "Λιγότερο από μία νύχτα την εβδομάδα",
        fr: "Moins d’une nuit par semaine",
        de: "",
        ka: "უფრო იშვიათად, ვიდრე კვირაში ერთხელ",
        fi: "Vähemmän kuin kerran viikossa",
      },
    },
    {
      value: "more_than_one_pw",
      text: {
        default: "One or more nights per week",
        gr: "Μία ή περισσότερες νύχτες την εβδομάδα",
        fr: "Une ou plusieurs nuits par semaine",
        de: "",
        ka: "კვირაში ერთ ღამეს ან უფრო ხშირად",
        fi: "Kerran viikossa tai useammin",
      },
    },
  ],
};

export const elementsS4 = [s4q1, s4q2, s4q3, s4q4];
